import React from 'react';

const userContext = React.createContext({
    isLoggedIn: false,
    token: null,
    login: () => {
    },
    logout: () => {
    },
    role: (value) => {
    },
    email: (value) => {
    },
    getLogInfo: () => {
    },
    getRole: () => {
    },
    getEmail: () => {
    }
});

export {userContext};