import React, {useContext, useEffect, useState} from "react"
import {
    Box, Button, Checkbox, createMuiTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControl, FormControlLabel, FormLabel,
    IconButton,
    ListItemIcon, Paper, Radio, RadioGroup
} from "@material-ui/core";
import MaterialTable from "material-table";
import {CheckBox, Close, LibraryBooks, Note} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {userContext} from '../UserContext';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from "@material-ui/core/styles";
import Aos from "aos";
import ReactHtmlParser from 'react-html-parser';
import {useHistory} from "react-router-dom";
import {yellow} from "@material-ui/core/colors";


const useStyles = makeStyles((theme) => ({
    saveClass: {
        display: 'flex',
        flexDirection: 'row',
    },
    radioBoxes: {
        marginTop: 20,
    },
    aboveHeaderButtons: {
        [theme.breakpoints.up('sm')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        },

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },

    headerBoxes: {
        minHeight: "20vh",
        maxHeight: "30vh",
        minWidth: "47vh",
        maxWidth: "100vh",

        display: "flex",
        justifyContent: "flex-start",
        marginTop: "2em",
        flexDirection: "column",

        [theme.breakpoints.up('sm')]: {
            minHeight: "17vh",
            maxHeight: "17vh",
            minWidth: "75vh",
            maxWidth: "100vh",
        },

        [theme.breakpoints.up('lg')]: {
            minWidth: "100vh",
        },
    },

    headerButtons: {
        position: "fixed",
        width: "47vh",
        [theme.breakpoints.up('sm')]: {
            width: "75vh",
        },
        [theme.breakpoints.up('md')]: {
            width: "75vh",
        },
        [theme.breakpoints.up('lg')]: {
            width: "100vh",
        },
    },
    headerText: {
        marginTop: "3em",
        [theme.breakpoints.up('sm')]: {
            marginTop: "2em",
        },
    },
    mainBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    htmlText: {
        padding: "2em",
        textAlign: "left",
        //background: "linear-gradient(to right, #fffcff, #d5fefd)",
    },


    //new CSS


    rootBox: {
        marginLeft: "13em",
        marginRight: "13em",
        padding: "2em",
    }
}));

function Library() {

    const history = useHistory();

    const [data, setData] = useState([])
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogName, setDialogName] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [dialogText, setDialogText] = useState("");

    const context = useContext(userContext);
    const [saveDialog, setSaveDialog] = useState(false);
    const [lists, setLists] = useState([]);
    const [bookID, setBookID] = useState("");
    const [bookTitle, setBookTitle] = useState("");

    const classes = useStyles()

    const [radioButtonValue, setRadioButtonValue] = useState('edition');
    const [returnFromRadio, setReturnFromRadio] = useState(false);
    const handleChangeRadioButton = (event) => {
        setRadioButtonValue(event.target.value);
        let puvodni = document.querySelectorAll("span.verze[typ='puvodni']");
        let editorska = document.querySelectorAll("span.verze[typ='editorska']");

        if (event.target.value === "edition") {
            for (let i = 0; i < puvodni.length; i++) {
                puvodni[i].style.display = "inline";
            }
            for (let i = 0; i < editorska.length; i++) {
                editorska[i].style.display = "none";
            }

        }
        if (event.target.value === "diplomatic") {
            for (let i = 0; i < editorska.length; i++) {
                editorska[i].style.display = "inline";
            }
            for (let i = 0; i < puvodni.length; i++) {
                puvodni[i].style.display = "none";
            }
        }
        if (event.target.value === "both") {
            let sbirka = document.querySelectorAll("html:not(obsah_odkazy)")[1];

            var elements = document.getElementsByClassName("obsah_odkazy");
            while (elements.length > 0) {
                elements[0].parentNode.removeChild(elements[0]);
            }

            var styleChange = document.querySelectorAll("div.sbirka");
            for (let i = 0; i < styleChange.length; i++) {
                styleChange[i].style.textAlign = "left";
            }

            var styleChangeHeader = document.querySelectorAll("div.nadpis");
            for (let i = 0; i < styleChangeHeader.length; i++) {
                styleChangeHeader[i].style.textAlign = "left";
            }


            for (let i = 0; i < puvodni.length; i++) {
                puvodni[i].style.display = "inline";
            }
            for (let i = 0; i < editorska.length; i++) {
                editorska[i].style.display = "none";
            }
            setSecondHtmlText(sbirka.innerHTML);
            // console.log(sbirka.innerHTML)

            for (let i = 0; i < puvodni.length; i++) {
                puvodni[i].style.display = "none";
            }
            for (let i = 0; i < editorska.length; i++) {
                editorska[i].style.display = "inline";
            }

            //console.log("DRUHÁ ************** ");
            // console.log(sbirka.innerHTML)


            setThirdHtmlText(sbirka.innerHTML);

            setReturnFromRadio(true);

        } else setReturnFromRadio(false);
    };


    const [bookDetails, setBookDetails] = useState(false);
    const [bookDescription, setBookDescription] = useState("");
    const [bookEditionNotes, setBookEditionNotes] = useState("");

    const [bookDetailsTrigger, setBookDetailsTrigger] = useState(true);
    const [bookDescriptionTrigger, setBookDescriptionTrigger] = useState(false);
    const [bookEditionNotesTrigger, setBookEditionNotesTrigger] = useState(false);


    const [headerOpen, setHeaderOpen] = useState(false);

    const [htmlText, setHtmlText] = useState(<p>some text</p>);
    const [secondHtmlText, setSecondHtmlText] = useState(<p>some text</p>);
    const [thirdHtmlText, setThirdHtmlText] = useState(<p>some text</p>);

    /* function handleReadAction(e, rowData) {
         setOpenDialog(true);
         setAuthorName(rowData.author)
         setDialogName(rowData.title)
         const requestOptions = {
             method: 'GET',
             headers: {'Content-Type': 'application/json'},
             id: rowData.id
         };
         const url = "https://ucl-poezie.fit.cvut.cz/api/books/" + rowData.id + "?content"
         fetch(url, requestOptions)
             .then(response => response.text())
             .then(data => {
                 setDialogText(data)
             })

         const bookDetailsURL = "https://ucl-poezie.fit.cvut.cz/api/books/" + rowData.id + "/headers/bookDetails"
         fetch(bookDetailsURL, requestOptions)
             .then(response => response.json())
             .then(data => {
                 setBookDetails(data)
                 //console.log(bookDetails)
             })

         const bookDescriptionURL = "https://ucl-poezie.fit.cvut.cz/api/books/" + rowData.id + "/headers/bookDescription"
         fetch(bookDescriptionURL, requestOptions)
             .then(response => response.json())
             .then(data => {
                 setBookDescription(data)
                 // console.log(bookDescription)
             })

         const bookEditionNotesURL = "https://ucl-poezie.fit.cvut.cz/api/books/" + rowData.id + "/headers/bookEditionNotes"
         fetch(bookEditionNotesURL, requestOptions)
             .then(response => response.json())
             .then(data => {
                 setBookEditionNotes(data)
                 //console.log(bookEditionNotes)
             })

         const bookFinalText = "https://ucl-poezie.fit.cvut.cz/api/books/" + rowData.id + "/text"
         fetch(bookFinalText, requestOptions)
             .then(response => response.text())
             .then(data => {
                 setHtmlText(data)
                 console.log(data)
                 setSecondHtmlText(data)
                 setThirdHtmlText(data);
                 // console.log(rowData.id)
             })
     }*/

    function handleReadAction2(e, rowData) {
        // console.log(rowData.id);
        rowData.isRouteFromLibrary = true;
        rowData.directLink = false;
        history.push({
            pathname: '/bookdetail/' + rowData.id,
            state: rowData
        });

    }

    function handleSaveAction(e, rowData) {
        setBookID(rowData.id)
        setBookTitle(rowData.title)
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists"
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLists(data)
                setSaveDialog(true)
            });
    }

    const handleClose = () => {
        setOpenDialog(false);
    };

    const closeSaveDialog = () => {
        setSaveDialog(false);
    };

    const makeAction = (id) => {
        const options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        const URL = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + encodeURIComponent(id)
        fetch(URL, options)
            .then(response => response.json())
            .then(data => {
                const newBook = {
                    bookId: bookID,
                    title: bookTitle,
                }
                const newBooks = data[0].books
                newBooks.push(newBook)
                data[0].books = newBooks

                const toSend = {
                    books: newBooks
                }

                const requestOptions = {
                    headers: {'Content-Type': 'application/json'},
                    method: 'PUT',
                    body: JSON.stringify(toSend)
                };
                const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + encodeURIComponent(id)
                fetch(url, requestOptions)
                    .then(response => {
                        if (response.status === 200)
                            closeSaveDialog()
                    })
            })
    };


    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        fetch('https://ucl-poezie.fit.cvut.cz/api/books?menu', requestOptions)
            .then(response => response.json())
            .then(data => {
                setData(data)
            });
        Aos.init({
            duration: 2000
        });
    }, []);


    function returnBookDetails() {
        return (<>
                {bookDetails.subtitle !== null && bookDetails.subtitle !== "" &&
                <Box display="flex" flexDirection="row">
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start"
                             style={{marginLeft: "0.5em", marginTop: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Podtitul:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"} style={{marginLeft: "0.5em", marginTop: "0.5em"}}>
                            {bookDetails.subtitle}
                        </Typography>
                    </Grid>
                </Box>
                }

                {bookDetails.publishingData !== null && bookDetails.publishingData !== "" &&
                <Box display="flex" flexDirection="row" style={{marginTop: "0.3em"}}>
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start" style={{marginLeft: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Nakladatelské údaje:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"}>
                            {bookDetails.publishingData}
                        </Typography>
                    </Grid>
                </Box>
                }

                {bookDetails.place !== null && bookDetails.place !== "" &&
                <Box display="flex" flexDirection="row" style={{marginTop: "0.3em"}}>
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start" style={{marginLeft: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Místo:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"}>
                            {bookDetails.place}
                        </Typography>
                    </Grid>
                </Box>
                }

                {bookDetails.place !== null && bookDetails.place !== "" &&
                <Box display="flex" flexDirection="row" style={{marginTop: "0.3em"}}>
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start" style={{marginLeft: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Vydání:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"}>
                            {bookDetails.edition}
                        </Typography>
                    </Grid>
                </Box>
                }

                {bookDetails.pages !== null && bookDetails.pages !== "" &&
                <Box display="flex" flexDirection="row" style={{marginTop: "0.3em"}}>
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start" style={{marginLeft: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Počet stran:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"}>
                            {bookDetails.pages}
                        </Typography>
                    </Grid>
                </Box>
                }


                {bookDetails.dedication !== null && bookDetails.dedication !== "" &&
                <Box display="flex" flexDirection="row" style={{marginTop: "0.3em"}}>
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start" style={{marginLeft: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Věnování:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"}>
                            {bookDetails.dedication}
                        </Typography>
                    </Grid>
                </Box>
                }

                {bookDetails.mottoAuthor !== null && bookDetails.mottoAuthor !== "" &&
                <Box display="flex" flexDirection="row" style={{marginTop: "0.3em"}}>
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start" style={{marginLeft: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Autor Mota:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"}>
                            {bookDetails.mottoAuthor}
                        </Typography>
                    </Grid>
                </Box>
                }

                {bookDetails.motto !== null && bookDetails.motto !== "" &&
                <Box display="flex" flexDirection="row" alignItems="flex-start"
                     style={{marginTop: "0.3em"}}>
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start" style={{marginLeft: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Moto:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"}>
                            {bookDetails.motto}
                        </Typography>
                    </Grid>
                </Box>
                }
            </>
        )
    }

    function returnBookDescription() {
        return (<>
                {bookDescription.description !== null && bookDescription.description !== "" &&
                <Box display="flex" flexDirection="row">
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start"
                             style={{marginLeft: "0.5em", marginTop: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Popis knihy:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"} style={{marginLeft: "0.5em", marginTop: "0.5em"}}>
                            {bookDescription.description}
                        </Typography>
                    </Grid>
                </Box>
                }

                {bookDescription.format !== null && bookDescription.format !== "" &&
                <Box display="flex" flexDirection="row" style={{marginTop: "0.3em"}}>
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start" style={{marginLeft: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Formát:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"}>
                            {bookDescription.format}
                        </Typography>
                    </Grid>
                </Box>
                }

                {bookDescription.sourceSignature !== null && bookDescription.sourceSignature !== "" &&
                <Box display="flex" flexDirection="row" style={{marginTop: "0.3em"}}>
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start" style={{marginLeft: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Zdroj-signatura:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"}>
                            {bookDescription.sourceSignature}
                        </Typography>
                    </Grid>
                </Box>
                }
            </>
        )
    }

    function returnEditionNotes() {
        return (<>
                {bookEditionNotes.editionNote !== null && bookEditionNotes.editionNote !== "" &&
                <Box display="flex" flexDirection="row">
                    <Grid md={3}>
                        <Box display="flex" alignItems="flex-start"
                             style={{marginLeft: "0.5em", marginTop: "0.5em"}}>
                            <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                                Ediční poznámka:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={9}>
                        <Typography variant={"subtitle2"} style={{marginLeft: "0.5em", marginTop: "0.5em"}}>
                            {bookEditionNotes.editionNote}
                        </Typography>
                    </Grid>
                </Box>
                }
            </>
        )
    }

    function handleDetailsTrigger() {
        setBookDetailsTrigger(true)
        setBookDescriptionTrigger(false)
        setBookEditionNotesTrigger(false)
    }

    function handleDescriptionTrigger() {
        setBookDescriptionTrigger(true)
        setBookDetailsTrigger(false)
        setBookEditionNotesTrigger(false)
    }

    function handleEditionTrigger() {
        setBookEditionNotesTrigger(true)
        setBookDetailsTrigger(false)
        setBookDescriptionTrigger(false)
    }

    function handleHeaderStateChange(event) {
        setHeaderOpen(event.target.checked)
    }

    return (
        <>
            <Box className={classes.rootBox}>
                <Typography variant="h3" style={{marginBottom: "1em"}}>Seznam knih</Typography>
                <MaterialTable
                    components={{
                        Container: props => <Paper {...props} elevation={1}/>
                    }}
                    columns={[
                        {
                            title: 'Id', field: 'id', type: 'numeric', hidden: true,
                        },
                        {
                            title: 'Název knihy', field: 'title', type: 'string', cellStyle: {
                                backgroundColor: 'white',
                                position: 'sticky',
                                left: 0
                            },
                            headerStyle: {
                                position: 'sticky',
                                left: 0,
                                whiteSpace: 'nowrap',
                                zIndex: 100
                            }
                        },
                        {title: 'Autor', field: 'author', type: 'string', headerStyle: {whiteSpace: 'nowrap',}},
                        {
                            title: 'Rok vydání',
                            field: 'releaseYear',
                            type: 'numeric',
                            headerStyle: {whiteSpace: 'nowrap',}
                        },
                        {
                            title: 'Místo vydání',
                            field: 'releasePlace',
                            type: 'string',
                            headerStyle: {whiteSpace: 'nowrap',}
                        },
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: "visibility",
                            tooltip: 'Číst knihu',
                            onClick: (event, rowData) => handleReadAction2(event, rowData)
                        },
                        /* {
                             icon: 'save',
                             tooltip: 'Uložit knihu',
                             onClick: (event, rowData) => handleSaveAction(event, rowData),
                         }*/
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            overflow: 'hidden',
                            // backgroundColor: "#009688",
                            fontWeight: 'bold',
                            //fontSize: "3em",
                            //color: "grey",
                        },
                        paging: true,
                        pageSize: 8,       // make initial page size
                        emptyRowsWhenPaging: true,   //to make page size fix in case of less data rows
                        pageSizeOptions: [8, 16, 30, 50],
                        showTitle: false,
                    }}
                    localization={{
                        header: {
                            actions: 'Akce'
                        },
                        toolbar: {
                            searchPlaceholder: "Vyhledávání"
                        },
                        body: {
                            emptyDataSourceMessage: "Žádné záznamy k zobrazení",
                        },
                        pagination: {
                            labelRowsSelect: "záznamů",
                            labelRowsPerPage: "záznamů na stránku",
                            firstAriaLabel: "První stránka",
                            firstTooltip: "První stránka",
                            previousAriaLabel: "Předchozí stránka",
                            previousTooltip: "Předchozí stránka",
                            nextAriaLabel: "Další stránka",
                            nextTooltip: "Další stránka",
                            lastAriaLabel: "Poslední stránka",
                            lastTooltip: "Poslední stránka"
                        }
                    }}
                />
            </Box>
            <Dialog onClose={handleClose} open={openDialog} fullScreen style={{textAlign: "center"}}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" fontWeight="fontWeightMedium">
                        <Box>
                            <LibraryBooks/>
                        </Box>
                        <Box flexGrow={1}>
                            {dialogName}
                        </Box>
                        <Box>
                            <IconButton onClick={handleClose}>
                                <Close/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Typography fontWeight="fontWeightLight">{authorName}</Typography>
                    <Box>
                        <FormControl component="fieldset" className={classes.aboveHeaderButtons}>
                            <RadioGroup row value={radioButtonValue} onChange={handleChangeRadioButton}>
                                <FormControlLabel value="edition" control={<Radio/>} label="Ediční"/>
                                <FormControlLabel value="diplomatic" control={<Radio/>} label="Diplomatická"/>
                                <FormControlLabel value="both" control={<Radio/>} label="Obě současně"/>
                            </RadioGroup>
                            <FormControlLabel
                                control={<Checkbox color="secondary" checked={headerOpen}
                                                   onChange={handleHeaderStateChange} name="jason"/>}
                                label="Zobrazit hlavičku"
                            />
                        </FormControl>
                    </Box>


                    {headerOpen && <Box className={classes.mainBox} data-aos="fade-zoom-in">
                        <Box className={classes.headerBoxes} border={1} borderRadius={8} overflow="auto">

                            <Box display="flex" className={classes.headerButtons}>
                                <Grid xs={3} sm={3}>
                                    <Button onClick={handleDetailsTrigger}
                                            style={{backgroundColor: "green", color: "white"}} fullWidth
                                            variant="contained">Údaje o knize</Button>
                                </Grid>
                                <Grid xs={2} sm={2}>
                                    <Button onClick={handleDescriptionTrigger} color="primary" fullWidth
                                            variant="contained">Popis knihy</Button>
                                </Grid>
                                <Grid xs={3} sm={3}>
                                    <Button onClick={handleEditionTrigger} color="secondary" fullWidth
                                            variant="contained">Ediční poznámka</Button>
                                </Grid>
                                <Grid xs={4} sm={4}>
                                    <Button style={{backgroundColor: "orange", color: "white"}} fullWidth
                                            variant="contained">Uživatelské poznámky</Button>
                                </Grid>
                            </Box>
                            <Box className={classes.headerText}>
                                {bookDetailsTrigger && returnBookDetails()}
                                {bookDescriptionTrigger && returnBookDescription()}
                                {bookEditionNotesTrigger && returnEditionNotes()}
                            </Box>
                        </Box>
                    </Box>
                    }
                </DialogTitle>
                <DialogContent dividers>


                    {returnFromRadio
                        ?
                        <Paper elevation={10} className={classes.htmlText}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    {ReactHtmlParser(secondHtmlText)}
                                </Grid>
                                <Grid item xs={6}>
                                    {ReactHtmlParser(thirdHtmlText)}
                                </Grid>
                            </Grid>
                        </Paper>
                        :
                        <Paper elevation={10} className={classes.htmlText}>
                            {ReactHtmlParser(htmlText)}
                        </Paper>
                    }


                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>


            {/*Ukládání knihy Dialog*/}

            <Dialog onClose={closeSaveDialog} open={saveDialog} fullWidth style={{textAlign: "center"}}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" fontWeight="fontWeightMedium">
                        <Box flexGrow={1}>Vyberte seznam pro uložení knihy</Box>
                        <Box>
                            <IconButton onClick={closeSaveDialog}>
                                <Close/>
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {lists.map((item, key) =>
                            <Grid item xs={12}>
                                <List className={classes.saveClass}>
                                    <ListItemIcon style={{alignItems: "center"}}>
                                        <Note color="primary"/>
                                    </ListItemIcon>
                                    <ListItem button key={key} onClick={() => makeAction(item._id)}>
                                        <ListItemText inset primary={item.name}/>
                                    </ListItem>
                                </List>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>


        </>
    )
}

//#e6f4f3

export default Library