import React, {useEffect, useState} from "react"
import './App.css';
import {CssBaseline, makeStyles} from "@material-ui/core";
import {Route} from "react-router-dom"
import Home from "./components/Homepage/Home"
import Library from "./components/Books/Library"
import Register from "./components/Authentication/Register";
import Login from "./components/Authentication/Login";
import Mybooks from "./components/MyBooks";
import About from "./components/About";
import Navbar, {AppBarOffset} from "./components/Navbar";
import BookManagement from "./components/BookManagement";
import UserManagement from "./components/UserManagement";
import ExtendedFilters from "./components/ExtendedFilters";
import {userContext} from './components/UserContext';
import {Redirect, Switch} from "react-router";
import Logout from "./components/Authentication/Logout"
import Authors from "./components/Authors/Authors";
import BookDetail from "./components/Books/BookDetail";
import PoemDetail from "./components/Books/PoemDetail";
import AuthorDetail from "./components/Authors/AuthorDetail";


const useStyles = makeStyles(theme => ({
    content: {
        // flexGrow: 1,
        //padding: theme.spacing(3),
    },
}))

function App() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [userRole, setUserRole] = useState("all");

    const login = () => {
        setLoggedIn(true);
    }
    const logout = () => {
        setLoggedIn(false);
    }

    const role = (value) => {
        setUserRole(value);
    }

    const email = (value) => {
        setUserEmail(value);
    }

    const getLogInfo = () => {
        return loggedIn
    }
    const getRole = () => {
        if (userRole === null)
            return "all"
        return userRole
    }
    const getEmail = () => {
        return userEmail
    }

    useEffect(() => {
        if (localStorage.getItem("loggedIn") !== null)
            setLoggedIn(JSON.parse(localStorage.getItem("loggedIn")))
        if (localStorage.getItem("userEmail") !== null)
            setUserEmail(JSON.parse(localStorage.getItem("userEmail")))
        if (localStorage.getItem("userRole") !== null)
            setUserRole(JSON.parse(localStorage.getItem("userRole")))
        else setUserRole("all")
    }, []);

    useEffect(() => {
        localStorage.setItem("loggedIn", JSON.stringify(loggedIn));
        localStorage.setItem("userEmail", JSON.stringify(userEmail));
        localStorage.setItem("userRole", JSON.stringify(userRole));
    }, [loggedIn, userEmail, userRole]);


    function renderSwitch() {
        switch (userRole) {
            case "Čtenář":
                return <>
                    <Route exact path="/" component={Home}/>
                    <Route path="/authors" component={Authors}/>
                    <Route path="/library" component={Library}/>
                    <Route path="/bookmanagement" component={BookManagement}/>
                    <Route path="/usermanagement" component={UserManagement}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/mybooks" component={Mybooks}/>
                    <Route path="/extendedfilters" component={ExtendedFilters}/>
                    <Route path="/about" component={About}/>
                    <Route path="/bookdetail/:id" component={BookDetail}/>
                    <Route path="/poemdetail" component={PoemDetail}/>
                    <Route path="/authordetail" component={AuthorDetail}/>

                    <Route path="/register" component={Home}/>
                    <Route path="/login" component={Home}/>

                    <Redirect from='*' to='/'/>
                </>
            case "all":
                return <>
                    <Route exact path="/" component={Home}/>
                    <Route path="/authors" component={Authors}/>
                    <Route path="/library" component={Library}/>
                    <Route path="/bookmanagement" component={BookManagement}/>
                    <Route path="/about" component={About}/>
                    <Route path="/bookdetail/:id" component={BookDetail}/>
                    <Route path="/poemdetail" component={PoemDetail}/>
                    <Route path="/authordetail" component={AuthorDetail}/>


                    <Route path="/usermanagement" component={UserManagement}/>
                    <Route path="/register" component={Register}/>
                    <Route path="/login" component={Login}/>

                    <Route path="/logout" component={Home}/>
                    <Route path="/mybooks" component={Home}/>
                    <Route path="/extendedfilters" component={Home}/>
                    <Route path="/usermanagement" component={Home}/>


                </>
            default:
                return;

        }
    }

    const styles = useStyles()
    return (


        <React.Fragment>
            <CssBaseline/>
            <userContext.Provider value={{
                isLoggedIn: loggedIn,
                getLogInfo: getLogInfo,
                login: login,
                logout: logout,
                role: role,
                email: email,
                getRole: getRole,
                getEmail: getEmail
            }}>
                <Navbar/>
                <AppBarOffset/>
                <main className={styles.content}>

                    <Switch>
                        {renderSwitch()}
                    </Switch>
                </main>
            </userContext.Provider>
        </React.Fragment>

    );
}

export default App;