import React, {useContext, useState} from "react"
import {Link} from "react-router-dom"
import {
    AppBar,
    makeStyles,
    Toolbar,
    IconButton,
    ListItemText,
    ListItem,
    Divider,
    List,
    Typography,
    Box,
    ListItemIcon, withStyles, TextField, InputAdornment,
} from "@material-ui/core"
import MuiListItem from "@material-ui/core/ListItem";
import Grow from '@mui/material/Grow';
import {
    Menu,
    Home,
    LibraryBooks,
    LocalLibrary,
    Memory,
    ExitToApp,
    VpnKey, Business, SupervisorAccount, Search
} from "@material-ui/icons"
import {userContext} from './UserContext'


import Drawer from "@material-ui/core/Drawer"
import PropTypes from 'prop-types'
import {Hidden} from '@material-ui/core'
import {useHistory} from "react-router-dom";
import historyContext from "react-router/modules/HistoryContext";
import DeleteIcon from "@material-ui/icons/Delete";
import logo from "../images/logo.png";
import {ClickAwayListener, styled, StyledComponentProps} from "@mui/material";
import {SearchBox} from "@fluentui/react";
import {useLocation} from "react-router";

const drawerWidth = 240

const useStyles = makeStyles(theme => ({

    offset: {
        ...theme.mixins.toolbar,
        marginTop: "6em",

        [theme.breakpoints.up(1930)]: {
            marginTop: "9em",
        },
    },

    logo: {},

    defaultNavbarContainer: {
        width: "70vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        marginLeft: "2em",

        [theme.breakpoints.down('sm')]: {
            display: "none"
        },

    },

    listItemStyle: {
        width: "15em",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },

    listItemTextStyle: {
        "&:hover": {
            color: "black",
        },
        color: "grey",
        textAlign: "center",
        //fontSize: "10em",
        width: "100%",
        fontSize: "1.7em",

    },

    listItemTextStyleSelected: {
        color: "black",
        textDecorationLine: 'underline',
        paddingLeft: "0em",
        textAlign: "center",
        width: "100%",

        [theme.breakpoints.up('lg')]: {
            fontSize: "1.7em"
        },

    },

    SearchIconStyle: {
        "&:hover": {
            color: "black",
        },
        color: "grey",
        //marginLeft: "12.58em",
        //marginRight: "0.5em",

        /*alignItems: "center",
        verticalAlign : "middle"*/
    },

    SearchBarStyle: {
        paddingBottom: "1em"
    },

    SearchBarHomepageStyle: {
        display: "none"
    },

    authItemHomepageStyle: {
        marginLeft: "25em",
    },

    authItemStyle: {
        display: "flex",
        alignItems: "center",


        gap: "0em",

        //marginLeft: "5em",


        [theme.breakpoints.down('sm')]: {
            display: "none"
        },

        [theme.breakpoints.up('xl')]: {
            //marginLeft: "40em",
        },
    },

    authItemTextStyle: {
        "&:hover": {
            color: "black",
        },
        width: "10em",
        paddingLeft: "0em",
        textAlign: "center",
        color: "grey",
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.7em"
        },
    },

    authItemTextStyleSelected: {
        width: "10em",
        paddingLeft: "0em",
        textAlign: "center",
        color: "black",
        textDecorationLine: 'underline',
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.7em"
        },
    },

    root: {
        height: "6vw",
        backgroundColor: "#ffffff",

    },

    SearchField: {
        // marginRight: "6em",
        width: "25em",
        // marginLeft: "3em",

    },


// Styles for a small devices


    toolbar: {
        ...theme.mixins.toolbar,
        backgroundColor: "#3f51b5",
    },

    drawer: {
        display: "flex"
    },

    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            overflow: "hidden"
        },
    },

    menuButton: {
        [theme.breakpoints.up('sm')]: {
            display: "none"
        },
    },

    smallNavbarContainer: {
        width: drawerWidth,
        backgroundColor: "#e6f4f3",
        height: "100%",
    },
}))


const menuItems = [
    {
        // listIcon: <Home/>,
        index: 0,
        listText: "Domů",
        listPath: "/",
        role: ["all", "Čtenář"]
    },
    {
        // listIcon: <LibraryBooks/>,
        index: 1,
        listText: "Knihy",
        listPath: "/library",
        role: ["all", "Čtenář"]
    },
    {
        // listIcon: <Memory/>,
        index: 2,
        listText: "Autoři",
        listPath: "/authors",
        role: ["all", "Čtenář"]
    },
    {
        // listIcon: <Business/>,
        index: 3,
        listText: "Pro školy",
        listPath: "/about",
        role: ["all", "Čtenář"]
    },
    {
        // listIcon: <SupervisorAccount/>,
        index: 4,
        listText: "Pro experty",
        listPath: "/about",
        role: ["Čtenář"]
    },
]

const authItems = [
    {
        //  listIcon: <ExitToApp/>,
        index: 5,
        listText: "Přihlášení",
        listPath: "/login",
        role: ["all"]
    },
    {
        // listIcon: <ExitToApp/>,
        index: 6,
        listText: "Odhlásit se",
        listPath: "/logout",
        role: ["Čtenář"]
    }
]


function Navbar(props) {

    const classes = useStyles()
    const {window} = props
    const context = useContext(userContext)

    const [state, setState] = useState({
        menuRight: false,
    })

    const toggleDrawer = (slider, open) => () => {
        setState({[slider]: open})
    }

//** SEARCH

    const [fullSearchValue, setFullSearchValue] = useState("");

// SEARCH **

// ** styles

    const [selectedListItemIndex, setSelectedListItemIndex] = React.useState(0);


    const handleListItemClick = (event, index) => {
        setSelectedListItemIndex(index);
    };

// styles **

    const SmallNavbar = (slider) => (
        <Box component="div" className={classes.smallNavbarContainer} onClick={toggleDrawer(slider, false)}>
            <div className={classes.toolbar}/>
            <Divider/>
            <List>
                {menuItems.filter(item => item.role.some(v => {
                    return v === context.getRole()
                })).map((item, key) =>
                    <ListItem button key={key} component={Link} to={item.listPath}>
                        <ListItemIcon className={classes.listItemStyle}>
                            {item.listIcon}
                        </ListItemIcon>
                        <ListItemText className={classes.listItemStyle} primary={item.listText}/>

                    </ListItem>
                )}
            </List>
        </Box>
    )


    const DefaultNavbar = () => (
        <>

            <List className={classes.defaultNavbarContainer}>
                {menuItems.filter(item => item.role.some(v => {
                    return v === context.getRole()
                })).map((item, key) =>
                    <ListItem disableRipple button key={key} component={Link} to={item.listPath}
                              className={classes.listItemStyle}
                              onClick={(event) => handleListItemClick(event, item.index)}>

                        <ListItemText
                            disableTypography
                            className={selectedListItemIndex === item.index ? classes.listItemTextStyleSelected : classes.listItemTextStyle}
                            primary={item.listText}/>

                    </ListItem>
                )}
            </List>
            <List><RightSideNavbarItems/></List>
        </>
    )

    const handleKeypress = e => {
        /* if (e.keyCode === 13) {
             fullTextSearching();
         }*/
    };

    function fullTextSearching() {
        /*const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            id: props.location.state._id
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + props.location.state._id + "?fulltext=" + encodeURIComponent(fullSearchValue)
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setData(data)
                setShowSearchChanger(true)
                setText("smthing")
            })*/
    }

    const location = useLocation();

    const RightSideNavbarItems = () => (
        <Box className={classes.authItemStyle}>


            <div className={location.pathname === "/" ? classes.SearchBarHomepageStyle : classes.SearchBarStyle}>

                {searchBarActive ?
                    <ClickAwayListener onClickAway={searchBarActive ? OpenSearchWindow : ""}>

                        <TextField
                            variant="standard"
                            className={classes.SearchField}
                            label="Vyhledávání"
                            fullWidth
                            autoFocus
                            onKeyDown={handleKeypress}
                            value={fullSearchValue}
                            onChange={(event) => setFullSearchValue(event.target.value)}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={() => fullTextSearching()}
                                                    className={classes.SearchIconStyle}>
                                            <Search/>
                                        </IconButton>
                                    </InputAdornment>,
                            }}
                        >
                        </TextField>
                    </ClickAwayListener>

                    :
                    <TextField
                        disabled
                        variant="standard"
                        className={classes.SearchField}
                        style={{paddingTop: "1.2em"}}
                        fullWidth
                        autoFocus
                        onChange={(event) => setFullSearchValue(event.target.value)}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton color="primary" onClick={() => OpenSearchWindow()}
                                                className={classes.SearchIconStyle}>
                                        <Search/>
                                    </IconButton>
                                </InputAdornment>,
                        }}
                    />
                }
            </div>


            {authItems.filter(item => item.role.some(v => {
                return v === context.getRole()
            })).map((item, key) =>
                <ListItem key={key} component={Link} to={item.listPath}
                          onClick={(event) => handleListItemClick(event, item.index)}
                          className={location.pathname === "/" ? classes.authItemHomepageStyle : ""}>
                    <ListItemText
                        disableTypography
                        className={selectedListItemIndex === item.index ? classes.authItemTextStyleSelected : classes.authItemTextStyle}
                        primary={item.listText}/>
                </ListItem>
            )}
        </Box>

    )

    const [searchBarActive, setSearchBarActive] = React.useState(false);


    const OpenSearchWindow = () => {
            console.log("čurák")
            setSearchBarActive(!searchBarActive)
        }
    ;

    const container = window !== undefined ? () => window().document.body : undefined


    return (

        <div>
            <AppBar position="fixed" elevation={0}>
                <Toolbar className={classes.root}>
                    <Link to="/" className={classes.logo}>
                        <img src={logo} alt="Logo UCL" style={{width: "100%", height: "100%"}}/>
                    </Link>

                    <DefaultNavbar/>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={toggleDrawer("menuRight", true)}
                        className={classes.menuButton}
                    >
                        <Menu/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor="right"
                        style={{overflow: "hidden"}}
                        open={state.menuRight}
                        onClose={toggleDrawer("menuRight", false)}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {SmallNavbar("rightSlider")}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    )
}

export function AppBarOffset() {
    const classes = useStyles()
    return <div className={classes.offset}/>
}

Navbar.propTypes =
    {
        window: PropTypes.func,
    }

export default Navbar