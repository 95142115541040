import React, {useContext, useState} from "react"
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button, Divider, IconButton, InputAdornment, Paper, TextField, Typography} from "@material-ui/core";
import {ArrowBack, Code, ListAlt, Search, Settings, Visibility,} from "@material-ui/icons";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import BackButton from "./BackButton";
import {userContext} from './UserContext';

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: "center",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        overflow: 'auto'
    },
    listPaperBegin: {

        display: "flex",
        flexDirection: "column",
        minHeight: "40vh",
        maxHeight: "40vh",

        [theme.breakpoints.up('md')]: {
            minHeight: "60vh",
            maxHeight: "60vh",
        },

        [theme.breakpoints.up('lg')]: {
            minHeight: "83vh",
            maxHeight: "83vh",
        },


    },
    listPaperMedium: {
        display: "flex",
        flexDirection: "column",
        minHeight: "80vh",
        maxHeight: "80vh",

        [theme.breakpoints.up('sm')]: {
            minHeight: "88vh",
            maxHeight: "88vh",
        },


        [theme.breakpoints.up('lg')]: {
            minHeight: "83vh",
            maxHeight: "83vh",
        },

    },
    listPaperLast: {
        display: "flex",
        flexDirection: "column",
        minHeight: "89vh",
        maxHeight: "89vh",
        overflow: "auto",

        [theme.breakpoints.up('sm')]: {
            minHeight: "60vh",
            maxHeight: "60vh",
        },

        [theme.breakpoints.up('lg')]: {
            minHeight: "83vh",
            maxHeight: "83vh",
        },


    },
    title: {
        textAlign: "center",
        padding: "1em"
    },
    page: {
        overflow: "hidden"
    },

    searchBarPaper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        margin: "2em"
    },

    input: {
        marginLeft: "1em",
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    filters: {
        marginTop: "3em",
    },
    titleIcons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "#e6f4f3",
    },
    titleIcon: {
        marginTop: "1em"
    },
    code: {
        alignItems: "center",
        margin: "1em"
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        margin: "2em",
        [theme.breakpoints.up('lg')]: {
            margin: "1em",
        },
    },
    button: {
        height: "4em",
        margin: "0.5em",
        [theme.breakpoints.up('lg')]: {
            height: "6em",
            margin: "1em",
        },
        [theme.breakpoints.up('xl')]: {
            height: "5em",
            margin: "0.5em",
        },
    },
    fields: {
        display: "flex",
        flexDirection: "column",
    },
    field: {
        margin: "0.5em",
        [theme.breakpoints.up('lg')]: {
            margin: "3em",
        },
    },
    showText: {
        padding: "1em", maxHeight: '100%', overflow: 'auto', textAlign: "center"
    }
}))


function ExtendedFilters(props) {

    const classes = useStyles()
    const context = useContext(userContext);

    const [text, setText] = useState("");
    const [data, setData] = useState({});
    const [showSearchChanger, setShowSearchChanger] = useState(false);
    const [showTextChanger, setShowTextChanger] = useState(false);
    const [dictionary, setDictionary] = useState([]);
    const [dictionaryTextChanger, setDictionaryTextChanger] = useState(false);

    const [fullSearchValue, setFullSearchValue] = useState("");
    const [contextSearchValue, setContextSearchValue] = useState("");


    function openText(id, title) {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            id: id
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/books/" + id + "?content"
        fetch(url, requestOptions)
            .then(response => response.text())
            .then(data => {
                setText(data)
                setShowTextChanger(false)
                setDictionaryTextChanger(false)
                setShowSearchChanger(false)
            })
    }

    function ShowText() {
        if (text !== "") {
            if (showSearchChanger) {
                return (
                    <>
                        <List className={classes.showText}
                              style={{textAlign: "start"}}>
                            <Typography variant={"subtitle1"} gutterBottom style={{whiteSpace: "pre-wrap"}}>
                                Celkový počet : {data.totalCount}
                            </Typography>
                            <Divider/>
                            {data.books.map((item) =>
                                <Typography variant={"subtitle2"} gutterBottom style={{whiteSpace: "pre-wrap"}}>
                                    {item.title.toString()} : {item.count.toString()}
                                </Typography>
                            )}
                        </List>
                    </>
                )
            }
            if (dictionaryTextChanger) {
                return (
                    <>
                        <List className={classes.showText}
                              style={showTextChanger ? {textAlign: "start"} : {textAlign: "center"}}>
                            {dictionary.map((item) =>
                                <Typography variant={"subtitle2"} gutterBottom style={{whiteSpace: "pre-wrap"}}>
                                    {item.word.toString()} : {item.count.toString()}
                                </Typography>
                            )}
                        </List>
                    </>
                )
            } else return (
                <List className={classes.showText}
                      style={showTextChanger ? {textAlign: "start"} : {textAlign: "center"}}>
                    <Typography variant={"subtitle2"} gutterBottom style={{whiteSpace: "pre-wrap"}}>
                        {text}
                    </Typography>
                </List>
            )
        }
        return null
    }

    function getAllTexts() {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            id: props.location.state._id
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + props.location.state._id + "?allTexts"
        fetch(url, requestOptions)
            .then(response => response.text())
            .then(data => {
                setText(data)
                setShowTextChanger(false)
                setDictionaryTextChanger(false)
                setShowSearchChanger(false)
            })
    }

    function getStatistics() {

        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            id: props.location.state._id
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + props.location.state._id + "?stats"
        fetch(url, requestOptions)
            .then(response => response.text())
            .then(data => {
                setText(data)
                setShowTextChanger(true)
                setDictionaryTextChanger(false)
                setShowSearchChanger(false)
            })
    }

    function getAlphabeticalDictionary() {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            id: props.location.state._id
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + props.location.state._id + "?alphabeticalDictionary"
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setDictionary(data)
                setShowTextChanger(true)
                setDictionaryTextChanger(true)
                setShowSearchChanger(false)
                setText("smthing")
            })
    }

    function getFrequencyDictionary() {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            id: props.location.state._id
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + props.location.state._id + "?frequencyDictionary"
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setDictionary(data)
                setShowTextChanger(true)
                setDictionaryTextChanger(true)
                setShowSearchChanger(false)
                setText("smthing")
            })
    }


    function fullTextSearching() {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            id: props.location.state._id
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + props.location.state._id + "?fulltext=" + encodeURIComponent(fullSearchValue)
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setData(data)
                setShowSearchChanger(true)
                setText("smthing")
            })
    }

    const handleKeypress = e => {
        if (e.keyCode === 13) {
            fullTextSearching();
        }
    };

    function contextSearching() {
        setDictionaryTextChanger(false)
        setShowTextChanger(false)
        setText("Momentálně nedostupné")
        /*  const requestOptions = {
              method: 'GET',
              headers: {'Content-Type': 'application/json'},
              id: props.location.state._id
          };
          const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + props.location.state._id + "?context=" + encodeURIComponent(fullSearchValue)
          fetch(url, requestOptions)
              .then(response => response.json())
              .then(data => {
                  console.log(data)
                  setData(data)
                  setShowSearchChanger(true)
                  setText("smthing")
              })*/
    }

    return (

        <Paper component="header" className={classes.page}>
            <BackButton>
                <ArrowBack style={{color: "black"}}/>
            </BackButton>

            <Grid container spacing={2} className={classes.container}>
                <Grid item lg={4} md={12} xs={12}>
                    <Paper className={classes.listPaperBegin} elevation={10}>
                        <Box className={classes.titleIcons} border={0}>
                            <ListAlt className={classes.titleIcon}/>
                            <Typography className={classes.title} variant="h6">
                                {props.location.state.name}
                            </Typography>
                        </Box>
                        <Divider/>
                        <List className={classes.column}>
                            {props.location.state.books.map((item) =>
                                <ListItem button onClick={() => openText(item.bookId, item.title)}>
                                    <ListItemText inset primary={item.title}/>
                                    <IconButton>
                                        <Visibility color="primary"/>
                                    </IconButton>
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                </Grid>

                <Grid item lg={4} md={12} xs={12}>
                    <Paper className={classes.listPaperMedium} elevation={5}>
                        <Box className={classes.titleIcons}>
                            <Code className={classes.code}/>
                        </Box>
                        <Divider/>
                        <ShowText/>
                    </Paper>
                </Grid>


                <Grid item lg={4} md={12} xs={12}>
                    <Paper className={classes.listPaperLast} elevation={10}>
                        <Box className={classes.titleIcons}>
                            <Settings className={classes.titleIcon}/>
                            <Typography className={classes.title} variant="h6">
                                Nástroje
                            </Typography>
                        </Box>
                        <Divider/>
                        <Box className={classes.buttons}>

                            <Button color="primary" size="large" className={classes.button} variant="contained"
                                    onClick={getAllTexts}>
                                Zobrazit vše
                            </Button>

                            <Button color="primary" size="large" className={classes.button} variant="contained"
                                    onClick={getStatistics}>
                                Statistika
                            </Button>

                            <Button color="primary" size="large" className={classes.button} variant="contained"
                                    onClick={getAlphabeticalDictionary}>
                                Abecední slovník
                            </Button>

                            <Button color="primary" size="large" className={classes.button} variant="contained"
                                    onClick={getFrequencyDictionary}>
                                Frekvenční slovník
                            </Button>

                        </Box>
                        <Box className={classes.fields}>

                            <Typography variant="subtitle1" align="center">
                                Vyhledávání
                            </Typography>

                            <TextField

                                variant="outlined"
                                className={classes.field}
                                label="fulltextové vyhledávání"
                                autoComplete={true}
                                onKeyDown={handleKeypress}
                                value={fullSearchValue}
                                onChange={(event) => setFullSearchValue(event.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton color="primary" onClick={fullTextSearching}>
                                                <Search/>
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                            />

                            <TextField
                                variant="outlined"
                                name="password"
                                className={classes.field}
                                label="slovo pro kontexty"
                                autoComplete={true}
                                value={contextSearchValue}
                                onChange={(event) => setContextSearchValue(event.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton color="primary" onClick={contextSearching}>
                                                <Search/>
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                            />

                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ExtendedFilters