import React, {useContext, useEffect, useState} from "react"
import {
    Box, Button, Checkbox, createMuiTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControl, FormControlLabel, FormLabel,
    IconButton,
    ListItemIcon, Paper, Radio, RadioGroup
} from "@material-ui/core";
import MaterialTable from "material-table";
import {ArrowBack, CheckBox, Close, LibraryBooks, Note} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {userContext} from '../UserContext';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from "@material-ui/core/styles";
import Aos from "aos";
import {yellow} from "@material-ui/core/colors";
import InfoPanel from "../Homepage/InfoPanel"
import {useHistory} from "react-router-dom";
import BackButton from "../BackButton";


const useStyles = makeStyles((theme) => ({

    container: {
        marginTop: "2em"
    },

    rootBox: {
        marginLeft: "13em",
        marginRight: "20em",
        padding: "2em",
    },

    headerText: {
        textAlign: "center",
        color: "grey",
        fontSize: "4em",
        textTransform: "capitalize",
        //marginLeft: "29vw",

        [theme.breakpoints.up(1930)]: {
            fontSize: "5em",
            //marginLeft: "32vw",
        },
    },

    bottomSectionPaper: {
        marginTop: "5em"
    },


    rightBottomSectionPaper: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        maxHeight: "100%",

        [theme.breakpoints.up(1930)]: {
            minHeight: "72vh",
            maxHeight: "72vh",
        },
    },

    infoPanelStyles: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        paddingTop: "12em",
        fontSize: "80%",
        gap: "12em"
    },

}));

function Authors(props) {

    const history = useHistory();

    const [data, setData] = useState([])

    const [authorName, setAuthorName] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [deathDate, setDeathDate] = useState("");

    const [subjects, setSubjects] = useState("");
    const [coAuthors, setCoAuthors] = useState("");

    const context = useContext(userContext);


    const classes = useStyles()


    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        fetch('https://ucl-poezie.fit.cvut.cz/api/authors/' + props.location.state._id +'?books', requestOptions)
            .then(response => response.json())
            .then(data => {
                //title, authors, releaseYear, releasePlace, odkaz na detail
                var dataToSet = data.books

               // console.log(dataToSet)
                setData(dataToSet)
            });
        Aos.init({
            duration: 2000
        });

        if (props && props.location && props.location.state && props.location.state.author)
            setAuthorName(props.location.state.author)

    }, [props]);

    function handleReadAction2(e, rowData) {

        rowData.isRouteFromLibrary = false;
        rowData.directLink = false;
        history.push({
            pathname: '/bookdetail',
            state: rowData
        });

    }

    const dummyData = [
        "Trochej",
        "Přerývaný verš"
    ]

    const menuItems = [
        {
            value : "Trochej"
        },
        {
            value : "Přerývaný verš"
        },
    ]
    return (
        <>
            <Grid container className={classes.container}>
                <Grid item lg={2} md={12} xs={12} direction="column">
                    <div style={{display: "flex", flexDirection: "row", marginLeft: "0.5em"}}>
                        <BackButton style={{marginTop: "1em", borderRadius: "5000"}}>
                            <ArrowBack style={{color: "black"}}/>
                        </BackButton>
                        <Typography variant="h6" style={{marginLeft: "0.5em", marginTop: "1.6em"}}>
                            Zpět na seznam autorů
                        </Typography>
                    </div>
                </Grid>

                <Grid item lg={8} md={12} xs={12}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <Typography inputProps={{style: {textTransform: 'capitalize'}}} className={classes.headerText}>
                            {authorName}
                        </Typography>
                        <Typography inputProps={{style: {textTransform: 'capitalize'}}}
                                    style={{textAlign: "center", color: "grey"}}>
                            *15.10 1924 Praha, 28.3.1979 Praha
                        </Typography>
                    </div>

                </Grid>
            </Grid>

            <Paper className={classes.bottomSectionPaper}>

                <Grid container>
                    <Grid item lg={9} md={9} xs={9}>
                        <Box className={classes.rootBox}>
                            <MaterialTable
                                data={data}
                                components={{
                                    Container: props => <Paper {...props} elevation={0}/>
                                }}
                                columns={[
                                    {title: 'Id', field: 'id', type: 'numeric', hidden: true,},
                                    {title: 'Název knihy', field: 'title', type: 'string',},
                                    {title: 'Autoři', field: '', type: 'string',},
                                    {title: 'Rok vydání', field: 'releaseYear', type: 'numeric', headerStyle: {whiteSpace: 'nowrap',}},
                                    {title: 'Místo vydání', field: 'releasePlace', type: 'string', headerStyle: {whiteSpace: 'nowrap',}},
                                ]}
                                actions={[
                                    {
                                        icon: "visibility",
                                        tooltip: 'Číst knihu',
                                        onClick: (event, rowData) => handleReadAction2(event, rowData)
                                    },
                                    /* {
                                         icon: 'save',
                                         tooltip: 'Uložit knihu',
                                         onClick: (event, rowData) => handleSaveAction(event, rowData),
                                     }*/
                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        overflow: 'hidden',
                                        // backgroundColor: "#009688",
                                        fontWeight: 'bold',
                                        //fontSize: "3em",
                                        //color: "grey",
                                    },
                                    paging: true,
                                    pageSize: 8,       // make initial page size
                                    emptyRowsWhenPaging: true,   //to make page size fix in case of less data rows
                                    pageSizeOptions: [8, 16, 30, 50],
                                    showTitle: false,
                                }}
                                localization={{
                                    header: {
                                        actions: ''
                                    },
                                    toolbar: {
                                        searchPlaceholder: "Vyhledávání"
                                    },
                                    body: {
                                        emptyDataSourceMessage: "Žádné záznamy k zobrazení",
                                    },
                                    pagination: {
                                        labelRowsSelect: "záznamů",
                                        labelRowsPerPage: "záznamů na stránku",
                                        firstAriaLabel: "První stránka",
                                        firstTooltip: "První stránka",
                                        previousAriaLabel: "Předchozí stránka",
                                        previousTooltip: "Předchozí stránka",
                                        nextAriaLabel: "Další stránka",
                                        nextTooltip: "Další stránka",
                                        lastAriaLabel: "Poslední stránka",
                                        lastTooltip: "Poslední stránka"
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <Paper className={classes.rightBottomSectionPaper}>
                            <Box className={classes.infoPanelStyles}>
                                <InfoPanel name="Spoluautoři" value="žádní"/>
                                <InfoPanel name="Témata" value="Trochej"/>

                            </Box>
                        </Paper>
                    </Grid>

                </Grid>
            </Paper>

        </>
    )
}


export default Authors