import React, {useContext, useEffect, useState} from "react"
import {
    Box, Button, Checkbox, createMuiTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControl, FormControlLabel, FormLabel,
    IconButton,
    ListItemIcon, Paper, Radio, RadioGroup
} from "@material-ui/core";
import MaterialTable from "material-table";
import {CheckBox, Close, LibraryBooks, Note} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {userContext} from '../UserContext';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from "@material-ui/core/styles";
import Aos from "aos";
import {useHistory} from "react-router-dom";
import {yellow} from "@material-ui/core/colors";
import {forEach} from "react-bootstrap/ElementChildren";


const useStyles = makeStyles((theme) => ({
    saveClass: {
        display: 'flex',
        flexDirection: 'row',
    },
    radioBoxes: {
        marginTop: 20,
    },
    aboveHeaderButtons: {
        [theme.breakpoints.up('sm')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        },

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },

    headerBoxes: {
        minHeight: "20vh",
        maxHeight: "30vh",
        minWidth: "47vh",
        maxWidth: "100vh",

        display: "flex",
        justifyContent: "flex-start",
        marginTop: "2em",
        flexDirection: "column",

        [theme.breakpoints.up('sm')]: {
            minHeight: "17vh",
            maxHeight: "17vh",
            minWidth: "75vh",
            maxWidth: "100vh",
        },

        [theme.breakpoints.up('lg')]: {
            minWidth: "100vh",
        },
    },

    headerButtons: {
        position: "fixed",
        width: "47vh",
        [theme.breakpoints.up('sm')]: {
            width: "75vh",
        },
        [theme.breakpoints.up('md')]: {
            width: "75vh",
        },
        [theme.breakpoints.up('lg')]: {
            width: "100vh",
        },
    },
    headerText: {
        marginTop: "3em",
        [theme.breakpoints.up('sm')]: {
            marginTop: "2em",
        },
    },
    mainBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    htmlText: {
        padding: "2em",
        textAlign: "left",
        //background: "linear-gradient(to right, #fffcff, #d5fefd)",
    },


    //new CSS


    rootBox: {
        marginLeft: "13em",
        marginRight: "13em",
        padding: "2em",
    }
}));

function Authors() {

    const history = useHistory();

    const [data, setData] = useState([])
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogName, setDialogName] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [dialogText, setDialogText] = useState("");

    const context = useContext(userContext);
    const [saveDialog, setSaveDialog] = useState(false);
    const [lists, setLists] = useState([]);
    const [bookID, setBookID] = useState("");
    const [bookTitle, setBookTitle] = useState("");

    const classes = useStyles()

    const [radioButtonValue, setRadioButtonValue] = useState('edition');
    const [returnFromRadio, setReturnFromRadio] = useState(false);


    const [htmlText, setHtmlText] = useState(<p>some text</p>);
    const [secondHtmlText, setSecondHtmlText] = useState(<p>some text</p>);
    const [thirdHtmlText, setThirdHtmlText] = useState(<p>some text</p>);


    function handleReadAction(e, rowData) {

        history.push({
            pathname: '/authordetail',
            state: rowData
        });

    }


    const handleClose = () => {
        setOpenDialog(false);
    };

    const closeSaveDialog = () => {
        setSaveDialog(false);
    };

    const makeAction = (id) => {
        const options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        const URL = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + encodeURIComponent(id)
        fetch(URL, options)
            .then(response => response.json())
            .then(data => {
                const newBook = {
                    bookId: bookID,
                    title: bookTitle,
                }
                const newBooks = data[0].books
                newBooks.push(newBook)
                data[0].books = newBooks

                const toSend = {
                    books: newBooks
                }

                const requestOptions = {
                    headers: {'Content-Type': 'application/json'},
                    method: 'PUT',
                    body: JSON.stringify(toSend)
                };
                const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + encodeURIComponent(id)
                fetch(url, requestOptions)
                    .then(response => {
                        if (response.status === 200)
                            closeSaveDialog()
                    })
            })
    };


    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        fetch('https://ucl-poezie.fit.cvut.cz/api/authors', requestOptions)
            .then(response => response.json())
            .then(data => {

                data.forEach((objekt, index) => {
                    if(objekt) {
                        objekt.autorName = objekt.firstName + ' ' + objekt.lastName
                        objekt.birthYear = objekt.birthdate.split(".")[2]
                        objekt.deathYear = objekt.dateOfDeath.split(".")[2]
                    }
                });

                // console.log(data)
                setData(data)
            });
        Aos.init({
            duration: 2000
        });
    }, []);


    return (
        <>
            <Box className={classes.rootBox}>
                <Typography variant="h3" style={{marginBottom: "1em"}}>Seznam Autorů</Typography>
                <MaterialTable
                    components={{
                        Container: props => <Paper {...props} elevation={1}/>
                    }}
                    columns={[

                        {title: 'Id', field: 'id', type: 'numeric', hidden: true,},
                        {
                            title: 'Autor', field: 'autorName', type: 'string', /*, cellStyle: {
                                backgroundColor: 'white',
                                position: 'sticky',
                                left: 0
                            },
                            headerStyle: {
                                position: 'sticky',
                                left: 0,
                                whiteSpace: 'nowrap',
                                zIndex: 100
                            }*/
                        },
                        {
                            title: 'Rok narození',
                            field: 'birthYear',
                            type: 'numeric',
                            headerStyle: {whiteSpace: 'nowrap'}
                        },
                        {
                            title: 'Rok úmrtí',
                            field: 'deathYear',
                            type: 'numeric',
                            headerStyle: {width: 50, maxWidth: 50},
                            cellStyle: {width: 50, maxWidth: 50}
                        },
                        {
                            title: 'Místo narození',
                            field: 'placeOfBirth',
                            type: 'string',
                            headerStyle: {whiteSpace: 'nowrap'}
                        },

                    ]}
                    data={data}
                    actions={[
                        {
                            icon: "visibility",
                            tooltip: 'Číst knihu',
                            onClick: (event, rowData) => handleReadAction(event, rowData)
                        },
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            overflow: 'hidden',
                            // backgroundColor: "#009688",
                            fontWeight: 'bold',
                            //fontSize: "3em",
                            //color: "grey",
                        },
                        paging: true,
                        pageSize: 8,       // make initial page size
                        emptyRowsWhenPaging: true,   //to make page size fix in case of less data rows
                        pageSizeOptions: [8, 16, 30, 50],
                        showTitle: false,
                        //tableLayout: "fixed",
                        tableLayout: 'auto'
                    }}
                    localization={{
                        header: {
                            actions: ''
                        },
                        toolbar: {
                            searchPlaceholder: "Vyhledávání"
                        },
                        body: {
                            emptyDataSourceMessage: "Žádné záznamy k zobrazení",
                        },
                        pagination: {
                            labelRowsSelect: "záznamů",
                            labelRowsPerPage: "záznamů na stránku",
                            firstAriaLabel: "První stránka",
                            firstTooltip: "První stránka",
                            previousAriaLabel: "Předchozí stránka",
                            previousTooltip: "Předchozí stránka",
                            nextAriaLabel: "Další stránka",
                            nextTooltip: "Další stránka",
                            lastAriaLabel: "Poslední stránka",
                            lastTooltip: "Poslední stránka"
                        }
                    }}
                />
            </Box>

        </>
    )
}

export default Authors