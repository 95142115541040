import React from "react"
import {Divider, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import {LocalCafe} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: "3em",
            marginRight: "3em",
            padding: "3em",
        },
        backgroundColor: "#e6f4f3",
        height: "88vh",
        marginLeft: "0.5em",
        marginRight: "0.5em",
        padding: "1em",
        overflow: "auto"
    },
    temporaryFun: {
        color: "blue",
        marginLeft: "1em"
    },
}))

function About() {
    const classes = useStyles()

    return (
        <Paper elevation={10} className={classes.root}>

            <Typography variant="h4">
                Vítejte na stránce o tomto projektu !!
                <LocalCafe className={classes.temporaryFun}/>
            </Typography>
            <Divider/>
            <Typography variant="h6" style={{marginTop: "2em"}}>
                Prozatím je obsah této stránky v procesu řešení a tak je zde prozatím tento text.
            </Typography>
            <Divider/>

            <Typography variant="h5" style={{marginTop: "2em"}}>
               Děkujeme za pochopení
            </Typography>


        </Paper>
    )
}

export default About