// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    /*background: linear-gradient(to right, #F5F7FA, #B8C6DB)*/
    /* background: linear-gradient(to right, #8693AB, #BDD4E7);*/
    background: linear-gradient(to right, #ffffff, #ffffff)/ /*  -webkit-background: cover;
       -moz-background: cover;
       -o-background: cover;
       background: cover;*/
}

html {
    overflow: auto;
    height: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,0DAA0D;IAC1D,4DAA4D;IAC5D,uDAAuD,EAAE;;;0BAGnC;AAC1B;;AAEA;IACI,cAAc;IACd,YAAY;AAChB","sourcesContent":["body {\r\n    /*background: linear-gradient(to right, #F5F7FA, #B8C6DB)*/\r\n    /* background: linear-gradient(to right, #8693AB, #BDD4E7);*/\r\n    background: linear-gradient(to right, #ffffff, #ffffff)/ /*  -webkit-background: cover;\r\n       -moz-background: cover;\r\n       -o-background: cover;\r\n       background: cover;*/\r\n}\r\n\r\nhtml {\r\n    overflow: auto;\r\n    height: 100%;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
