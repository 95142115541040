import React from 'react'
import {withRouter} from 'react-router'
import {IconButton} from "@material-ui/core";

const BackButton = ({history: {goBack}, children, ...props}) => (
    <IconButton {...props} onClick={goBack}>
        {children}
    </IconButton>
)

export default withRouter(BackButton)