import React, {useContext, useEffect, useState} from "react"
import {Typography, Grid, Paper, Icon, IconButton, Box} from "@material-ui/core";
import {makeStyles, InputAdornment} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import {Error, MailOutlined, MoodOutlined, Visibility, VisibilityOff,} from "@material-ui/icons";
import {Link, useHistory} from "react-router-dom";
import Aos from "aos";
import {blueGrey} from "@material-ui/core/colors";
import {userContext} from '../UserContext';


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(5, 0, 2),
        backgroundColor: "black",
        color: "white"
    },
    paper: {
        backgroundColor: "white",
        padding: theme.spacing(2),
        width: "100%",
    },
    done: {
        backgroundColor: "whitesmoke",
        padding: theme.spacing(2),
        borderRadius: 25,
        margin: theme.spacing(2),
    },
    centerDone: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "center",
    },
    iconDone: {
        marginLeft: theme.spacing(2),
    }
}));

function SignUp() {
    const classes = useStyles();
    const context = useContext(userContext);
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [syntaxError, setSyntaxError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        Aos.init({duration: 2000});
    }, []);

    function sha512(str) {
        return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
            return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
        });
    }

    function HandleSubmit() {
        sha512(password).then(x => {

            const requestOptions = {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            };
            const url = "https://ucl-poezie.fit.cvut.cz/api/login?email=" + encodeURIComponent(email) + "&password=" + encodeURIComponent(x)
            //const url = "http://localhost:8888/login?email=" + encodeURIComponent(email) + "&password=" + encodeURIComponent(x)
            fetch(url, requestOptions)
                .then(response => {
                    if (response.status === 200) {
                        setSyntaxError(false)
                        const requestOptions = {
                            method: 'GET',
                            headers: {'Content-Type': 'application/json'},
                        };
                        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(email)
                        fetch(url, requestOptions)
                            .then(response => response.json())
                            .then(data => {
                                context.role(data.role)
                                context.email(email)
                                history.push("/")
                            });
                    } else setSyntaxError(true)
                })
        });
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleKeypress = e => {
        if (e.keyCode === 13) {
            HandleSubmit();
        }
    };

    return (
        <Container component="main" maxWidth="xs" style={{marginTop: "5em", display:"flex", flexDirection:"column", alignItems:"center"}}>
            <CssBaseline/>
            <div style={{width: "50vw", textAlign:"center"}}>
                <Typography component="h1" variant="h2">
                    Přihlášení
                </Typography>
                <Typography style={{marginTop: "1.5em"}}>
                    Přihlaste se a začněte využívat Českou elektronickou knihovnu naplno !
                </Typography>
            </div>
            <Box variant="outlined" className={classes.paper}>
                <div className={classes.root}>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    onKeyDown={handleKeypress}
                                    required
                                    fullWidth
                                    autoFocus
                                    id="email"
                                    label="Emailová adresa"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    name="email"
                                    autoComplete="email"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <Icon style={{marginRight: "12px"}}>
                                                <MailOutlined/>
                                            </Icon>
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    onKeyDown={handleKeypress}
                                    name="password"
                                    label="Heslo"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    id="password"
                                    autoComplete="current-password"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            {/*<LockOutlined/>*/}
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                style={{color: "black"}}
                                            >
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {!userContext.isLoggedIn && <Button
                            type="reset"
                            fullWidth
                            variant="contained"
                            size="large"
                            className={classes.submit}
                            onClick={HandleSubmit}
                        >
                            Přihlásit se
                        </Button>}
                        <div style={{display: "flex", justifyContent: "space-evenly"}}>
                            <Typography style={{fontSize: "1em"}}>Nemáte ještě vlastní účet? </Typography>
                            <Link to="Register" color="primary">
                                {"Založte si ho nyní"}
                            </Link>
                        </div>
                    </form>
                </div>
            </Box>


            {syntaxError ?
                <Paper variant="outlined" className={classes.done} data-aos="fade-up">
                    <div className={classes.centerDone}>
                        <Typography variant="subtitle1" align="center">
                            Neplatné přihlašovací údaje
                        </Typography>
                        <Icon className={classes.iconDone}>
                            <Error/>
                        </Icon>
                    </div>
                </Paper>
                : ""
            }
        </Container>


    );
}

export default SignUp