import React, {useEffect, useState} from "react"
import {makeStyles,} from "@material-ui/core";
import "aos/dist/aos.css"
import MaterialTable from "material-table";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        justifyContent: "space-center"
    },
}))

const roles = [
    {id: 1, title: "Čtenář"},
]

function UserManagement() {

    const classes = useStyles;
    const [data, setData] = useState([])
    const [categories, setCategories] = useState({})


    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        fetch('https://ucl-poezie.fit.cvut.cz/api/users', requestOptions)
            .then(response => response.json())
            .then(data => {
                setData(data)
            });
    }, []);

    useEffect(() => {
        const tmp = {}
        roles.map(row => tmp[row.title] = row.title)
        setCategories(tmp)
    }, [])

    function handleUpdateAction(newData, oldData) {
        const toSend = {
            email: newData.email,
            firstName: newData.firstName,
            lastName: newData.lastName,
            role: newData.role
        }
        const requestOptions = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(toSend)
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(oldData.email)
        fetch(url, requestOptions)
            .then(response => console.log(response.status))
    }

    function handleDeleteAction(newData) {
        const requestOptions = {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(data[newData].email)
        fetch(url, requestOptions)
            .then(response => console.log(response.status))
    }

    return (
        <MaterialTable
            title="Přehled uživatelů"
            columns={[
                {
                    title: 'Jméno', field: 'firstName', type: 'string', cellStyle: {
                        backgroundColor: 'white',
                        position: 'sticky',
                        left: 0
                    },
                    headerStyle: {
                        position: 'sticky',
                        left: 0,
                        whiteSpace: 'nowrap',
                        zIndex: 100
                    }
                },
                {title: 'Příjmení', field: 'lastName', type: 'string'},
                {title: 'email', field: 'email', type: 'string'},
                {title: 'role', field: 'role', lookup: categories},

            ]}
            data={data}
            options={{
                actionsColumnIndex: -1,
                headerStyle: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    backgroundColor: "#009688",
                    fontWeight: 'bold',
                    color: "white",
                },
                paging: true,
                pageSize: 20,       // make initial page size
                emptyRowsWhenPaging: true,   //to make page size fix in case of less data rows
                pageSizeOptions: [10, 20, 50, 100],
            }}
            editable={{
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataDelete = [...data];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            setData([...dataDelete]);
                            handleDeleteAction(index)
                            resolve();
                        }, 1000);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataUpdate = [...data];
                            const index = oldData.tableData.id;
                            if (newData.firstName !== "" && newData.lastName !== "" && newData.email === oldData.email) {
                                dataUpdate[index] = newData;
                                setData([...dataUpdate]);
                                handleUpdateAction(newData, oldData)
                                resolve();
                            }
                            resolve();
                        }, 1000);
                    }),
            }}
            icons={{
                Delete: "delete",
            }}
            localization={{
                header: {
                    actions: 'Akce'
                },
                toolbar: {
                    searchPlaceholder: "Vyhledávání"
                },
                body: {
                    emptyDataSourceMessage: "Žádné záznamy k zobrazení",
                    editRow: {
                        deleteText: "Opravdu chcete tento záznam smazat ?",
                        cancelTooltip: "Zrušit akci",
                        saveTooltip: "Provést akci",
                        deleteTooltip: "Smazat",
                        editTooltip: "Upravit",
                    }
                },
                pagination: {
                    labelRowsSelect: "záznamů",
                    labelRowsPerPage: "záznamů na stránku",
                    firstAriaLabel: "První stránka",
                    firstTooltip: "První stránka",
                    previousAriaLabel: "Předchozí stránka",
                    previousTooltip: "Předchozí stránka",
                    nextAriaLabel: "Další stránka",
                    nextTooltip: "Další stránka",
                    lastAriaLabel: "Poslední stránka",
                    lastTooltip: "Poslední stránka"
                }
            }}
        />
    )
}

export default UserManagement