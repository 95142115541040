import React from "react"
import Header from "./Header";
import InfoPanels from "./InfoPanels";
import PoemOfTheDay from "./PoemOfTheDay";
import {makeStyles} from "@material-ui/core";
import {Box} from "@mui/material";

const useStyles = makeStyles(theme => ({
    content: {
       // flexGrow: 1,
        //padding: theme.spacing(3),
        //overflow: "auto"
    },
}))

const Home = () => {

    const classes = useStyles()

    return (
        <Box className={classes.content}>
            <Header/>
            <InfoPanels/>
            <PoemOfTheDay/>
        </Box>
    )
}

export default Home