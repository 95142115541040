import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    TextField,
} from "@material-ui/core";
import {
    Add,
    Close,
    ExpandLess,
    ExpandMore,
    FilterList,
    HighlightOff,
    LibraryBooks,
    Visibility
} from "@material-ui/icons";
import {red} from "@material-ui/core/colors";
import {useHistory} from "react-router-dom";
import {userContext} from './UserContext';


const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerpaper: {
        backgroundColor: "#e6f4f3",
        padding: theme.spacing(5),
        width: "100%",

        [theme.breakpoints.up('md')]: {
            width: "70%",
        },

        [theme.breakpoints.up('lg')]: {
            width: "50%",
            padding: theme.spacing(4),
            margin: theme.spacing(4),
        },
    },
    listpaper: {
        backgroundColor: "#e6f4f3",
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        width: "100%",

        [theme.breakpoints.up('md')]: {
            width: "70%",
        },

        [theme.breakpoints.up('lg')]: {
            backgroundColor: "#e6f4f3",
            padding: theme.spacing(2),
            width: "50%",
        },
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
    zaobleni: {
        borderRadius: 20,
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
    },
    dialogTitle: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        alignItems: "center",
    },
    textField: {}

}));


export default function BookList() {
    const classes = useStyles();

    const context = useContext(userContext);
    const history = useHistory();

    const [lists, setLists] = useState([]);
    const [title, setTitle] = useState("");

    // dialog452
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogName, setDialogName] = useState("");
    const [dialogText, setDialogText] = useState("");

    const [textFieldError, setTextFieldError] = useState(false);


    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists"
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLists(data)
                //console.log(data)
            });
    }, [context]);
//001010

    const reloadData = () => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists"
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLists(data)
            });
    }
    const handleClick = id => {
        setLists(
            lists.map(item => item._id === id ? {...item, open: !item.open} : item)
        )
    }

    function deleteList(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + encodeURIComponent(id)
        fetch(url, requestOptions)
            .then(response => reloadData())
    }

    function deleteSubList(listID, bookID) {
        let actualBooks = []
        let toState = []
        lists.map(item => item._id === listID ? actualBooks = item.books : console.log(""))
        actualBooks = actualBooks.filter(item => item.bookId !== bookID)
        const toSend = {
            books: actualBooks
        }
        const requestOptions = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(toSend)
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + encodeURIComponent(listID)
        fetch(url, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    setLists(prevState => {
                        prevState.map(item => item._id === listID ? toState = item : console.log(""))
                        toState.books = actualBooks
                        return [...prevState];
                    })
                }
            })
    }

    const handleKeypress = e => {
        if (e.keyCode === 13) {
            handleCreate();
        }
    };

    const handleClickOpen = (id, title) => {
        setOpenDialog(true);
        setDialogName(title)
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            id: id
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/books/" + id + "?content"
        fetch(url, requestOptions)
            .then(response => response.text())
            .then(data => {
                setDialogText(data)
            })
    };
    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleCreate = () => {
        setTextFieldError(false)
        if (title === "" || title.length > 20)
            setTextFieldError(true)
        else {
            const toSend = {
                name: title
            }
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(toSend)
            };
            const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists"
            fetch(url, requestOptions)
                .then(response => {
                    if (response.status > 200 && response.status < 300)
                        reloadData()
                })
        }
    }

    const toExtendedFilters = (item) => {
        if (item.books.length >= 1) {
            history.push({
                pathname: '/extendedFilters',
                state: item
            });
        }
    }


    return (
        <>
            <div className={classes.root}>

                <Paper className={classes.headerpaper} elevation={10}>
                    <form>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={3}
                        >

                            <Grid item md={6} xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="listcreator"
                                    label="Název nového seznamu"
                                    onKeyDown={handleKeypress}
                                    className={classes.textField}
                                    error={textFieldError}
                                    helperText={textFieldError ? "Název seznamu musí být v rozmezí 1-20 znaků" : ""}
                                    value={title}
                                    onChange={(event) => setTitle(event.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    type="reset"
                                    variant="contained"
                                    color="primary"
                                    style={{backgroundColor: "#009688"}}
                                    size="large"
                                    className={classes.zaobleni}
                                    startIcon={<Add/>}
                                    onClick={handleCreate}
                                >
                                    Vytvořit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>


                <Paper className={classes.listpaper} elevation={10}>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                Uložené seznamy
                            </Typography>


                            {lists.map((item) =>
                                <Grid item xs={12}>

                                    <List className={classes.list}>
                                        <IconButton onClick={() => deleteList(item._id)}>
                                            <DeleteIcon style={{color: "black"}}/>
                                        </IconButton>
                                        <IconButton label="Rozšířené filtry" onClick={() => toExtendedFilters(item)}>
                                            <FilterList color="primary"/>
                                        </IconButton>
                                        <ListItem button key={item._id} onClick={() => handleClick(item._id)}>
                                            <ListItemText inset primary={item.name}/>
                                            {item.open ? <ExpandLess/> :
                                                <ExpandMore/>}
                                        </ListItem>
                                    </List>

                                    <Collapse in={item.open} timeout="auto"
                                              unmountOnExit>
                                        {item.books.map((book, key) =>
                                            <List component="div" disablePadding className={classes.list}>

                                                <ListItem key={key} button className={classes.nested}
                                                          onClick={() => handleClickOpen(book.bookId, book.title)}>
                                                    <ListItemText inset primary={book.title}/>
                                                </ListItem>
                                                <IconButton onClick={() => handleClickOpen(book.bookId, book.title)}>
                                                    <Visibility color="primary"/>
                                                </IconButton>
                                                <IconButton onClick={() => deleteSubList(item._id, book.bookId)}>
                                                    <HighlightOff style={{color: red[500]}}/>
                                                </IconButton>
                                            </List>
                                        )}
                                    </Collapse>

                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </div>

            <Dialog onClose={handleClose} open={openDialog} fullWidth style={{textAlign: "center"}}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" fontWeight="fontWeightMedium">
                        <Box>
                            <LibraryBooks/>
                        </Box>
                        <Box flexGrow={1}>{dialogName}</Box>
                        <Box>
                            <IconButton onClick={handleClose}>
                                <Close/>
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{whiteSpace: "pre-wrap"}}>
                        {dialogText}
                    </Typography>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </>
    );
}