import React, {useEffect, useState} from "react"
import {Typography, Grid, Paper, Icon, IconButton, Box} from "@material-ui/core";
import {makeStyles, InputAdornment} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import {
    CheckCircle,
    Error,
    Face,
    FaceOutlined,
    MailOutlined, MoodOutlined, Visibility, VisibilityOff,
} from "@material-ui/icons";
import Aos from "aos";
import {blueGrey} from "@material-ui/core/colors";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(5, 0, 2),
        backgroundColor: "black",
        color: "white"
    },
    paper: {
        backgroundColor: "white",
        padding: theme.spacing(2),
        width: "100%",
    },
    done: {
        backgroundColor: "white",
        padding: theme.spacing(2),
        borderRadius: 25,
        margin: theme.spacing(2),
    },
    centerDone: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "center",
    },
    iconDone: {
        marginLeft: theme.spacing(2),
    }
}));

function SignUp() {
    const classes = useStyles();

    useEffect(() => {
        Aos.init({
            duration: 2000
        });
    }, []);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [done, setDone] = useState(false);
    const [syntaxError, setSyntaxError] = useState(false);
    const [serverError, setServerError] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const history = useHistory();


    function sha512(str) {
        return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
            return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
        });
    }

    function handleSubmit() {
        if (firstName.match(/^[A-Za-zÁÄÉËĚÍÓÖÔÚŮÜÝČĎŇŘŠŤŽĹĽáäéëěíóöôúůüýčďňřšťžĺľ]+$/) !== null &&
            lastName.match(/^[A-Za-zÁÄÉËĚÍÓÖÔÚŮÜÝČĎŇŘŠŤŽĹĽáäéëěíóöôúůüýčďňřšťžĺľ]+$/) !== null &&
            !isEmail() &&
            password.length >= 6) {

            sha512(password).then(x => {
                const data = {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    password: x
                }
                registerUser(data)
            });
        } else {
            setSyntaxError(true)
            setServerError(false)
            setDone(false)
        }
    }

    function isEmail() {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(email) && email !== "") {
            return true;
        }
    }

    function registerUser(data) {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        };
        fetch('https://ucl-poezie.fit.cvut.cz/api/users', requestOptions)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    setDone(true)
                    setFirstName("")
                    setLastName("")
                    setEmail("")
                    setPassword("")
                    setServerError(false)
                    setSyntaxError(false)
                } else {
                    setServerError(true)
                    setSyntaxError(false)
                    setDone(false)
                }
            }).catch(err => err);
    }

    function navigateToLogin() {
        history.push({
            pathname: '/login',
        });
    }

    const handleKeypress = e => {
        if (e.keyCode === 13) {
            handleSubmit();
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };


    return (
        <Container component="main" maxWidth="xs" style={{marginTop: "5em", display:"flex", flexDirection:"column", alignItems:"center"}}>
            <CssBaseline/>
            <div style={{width: "50vw", textAlign:"center"}}>
                <Typography component="h1" variant="h2">
                    Registrace
                </Typography>
                <Typography style={{marginTop: "1.5em"}}>
                    Zaregistrujte se nyní a začněte využívat Českou elektronickou knihovnu naplno !
                </Typography>
            </div>
            <Box variant="outlined" className={classes.paper}>
                <div className={classes.root}>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={5}>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    onKeyDown={handleKeypress}
                                    label="Jméno"
                                    error={!firstName.match(/^[A-Za-zÁÄÉËĚÍÓÖÔÚŮÜÝČĎŇŘŠŤŽĹĽáäéëěíóöôúůüýčďňřšťžĺľ]+$/) && firstName !== ""}
                                    value={firstName}
                                    helperText={!firstName.match(/^[A-Za-zÁÄÉËĚÍÓÖÔÚŮÜÝČĎŇŘŠŤŽĹĽáäéëěíóöôúůüýčďňřšťžĺľ]+$/) && firstName !== "" ? "Neplatné jméno" : ""}
                                    onChange={(event) => setFirstName(event.target.value)}
                                    autoFocus
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Icon style={{marginRight: "12px"}}>
                                                    <Face/>
                                                </Icon>
                                            </InputAdornment>,
                                    }}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="family-name"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Příjmení"
                                    onKeyDown={handleKeypress}

                                    name="lastName"
                                    error={!lastName.match(/^[A-Za-zÁÄÉËĚÍÓÖÔÚŮÜÝČĎŇŘŠŤŽĹĽáäéëěíóöôúůüýčďňřšťžĺľ]+$/) && lastName !== ""}
                                    helperText={!lastName.match(/^[A-Za-zÁÄÉËĚÍÓÖÔÚŮÜÝČĎŇŘŠŤŽĹĽáäéëěíóöôúůüýčďňřšťžĺľ]+$/) && lastName !== "" ? "Neplatné příjmení" : ""}
                                    value={lastName}

                                    onChange={(event) => setLastName(event.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <Icon style={{marginRight: "12px"}}>
                                                <FaceOutlined/>
                                            </Icon>
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Emailová adresa"
                                    onKeyDown={handleKeypress}

                                    error={isEmail()}
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    helperText={isEmail() ? "Neplatný email" : ""}

                                    name="email"
                                    autoComplete="email"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <Icon style={{marginRight: "12px"}}>
                                                <MailOutlined/>
                                            </Icon>

                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    onKeyDown={handleKeypress}
                                    label="Heslo"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    error={password.length !== 0 && password.length < 6}
                                    helperText={password.length !== 0 && password.length < 6 ? "Heslo musí být dlouhé aspoň 6 znaků" : ""}
                                    id="password"
                                    autoComplete="current-password"
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                {/*<LockOutlined/>*/}
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    style={{color: "black"}}
                                                >
                                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="reset"
                            fullWidth
                            variant="contained"
                            size="large"
                            onClick={handleSubmit}
                            className={classes.submit}
                        >
                            Zaregistrovat se
                        </Button>
                    </form>
                </div>
            </Box>


            {done ?
                <>
                    <Paper variant="outlined" className={classes.done} data-aos="fade-up">
                        <div className={classes.centerDone}>
                            <Typography variant="subtitle1" align="center">
                                Registrace proběhla úspěšně
                            </Typography>
                            <Icon className={classes.iconDone}>
                                <CheckCircle/>
                            </Icon>
                        </div>
                        <Typography variant="subtitle1" align="center" onClick={navigateToLogin}
                                    style={{color: "#009688", cursor: "pointer"}}>
                            Přihlásit se
                        </Typography>
                    </Paper>
                </>
                : ""}
            {syntaxError ?
                <Paper variant="outlined" className={classes.done} data-aos="fade-up">
                    <div className={classes.centerDone}>
                        <Typography variant="subtitle1" align="center">
                            Nastala chyba.
                            Zkontrolujte ještě jednou zadané údaje
                        </Typography>
                        <Icon className={classes.iconDone}>
                            <Error/>
                        </Icon>
                    </div>
                </Paper>
                : ""
            }
            {serverError ?
                <Paper variant="outlined" className={classes.done} data-aos="fade-up">
                    <div className={classes.centerDone}>
                        <Typography variant="subtitle1" align="center">
                            Nastala chyba.
                            Tento uživatel je již zaregistrován
                        </Typography>
                        <Icon className={classes.iconDone}>
                            <Error/>
                        </Icon>
                    </div>
                </Paper>
                : ""
            }
        </Container>
    );
}

export function Formstyles() {
    return useStyles();
}

export default SignUp