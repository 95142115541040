import React, {useEffect, useState} from "react"
import {
    Typography,
    makeStyles, Paper, Divider, TextField, IconButton
} from "@material-ui/core";
import Aos from "aos"
import "aos/dist/aos.css"
import {Clear, EmojiEmotions, Search} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import {Box} from "@mui/material";

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: "center",
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "row",
    },
    poemTitleContainer: {
        backgroundColor: "#ecebeb",
        paddingTop: "4em",
        paddingLeft: "6em",

        [theme.breakpoints.up(1930)]: {
            paddingTop: "6em",
        },

    },
    poemTitleHeader: {
        color: "grey",
        textAlign: "left",
        fontSize : "3em",

        [theme.breakpoints.up(1930)]: {
            fontSize : "4.3em",
        },
    },

    poemTitleTextContainer: {
        padding: "4em",
        width: "50vw",
        height: "17.7em",

        [theme.breakpoints.up(1930)]: {
            padding: "8em",
            height: "30em"
        },
    },
    poemTitleTextPoem: {
        color: "grey",
        fontWeight: "bold",
        fontSize : "3em",

        [theme.breakpoints.up(1930)]: {
            fontSize : "4.3em",
        },

    },
    poemTitleTextAuthor: {
        color: "grey",
        marginLeft: "4em",
        fontSize : "1.4em",


        [theme.breakpoints.up(1930)]: {
            fontSize : "1.7em",

        },
    },

    poemTextContainer: {
        paddingLeft: "3em",
        paddingRight: "3em",
        paddingTop: "1em",
        backgroundColor: "#ffffff",
        height: "20em",
        width: "50vw",

        [theme.breakpoints.up(1930)]: {
            height: "20em",

        },

    },
    poemText: {
        color: "grey",
        textAlign: "center",
        verticalAlign: "middle",
        fontSize : "2em",



        [theme.breakpoints.up(1930)]: {
            fontSize : "2.4em",

        },
    },
}))

const InfoPanel = (props) => {

    const classes = useStyles()

    return (
        <div className={classes.panelContainer}>
            <Typography variant="h4" className={classes.panelName}>
                {props.name}
            </Typography>
            <Typography variant="h4" className={classes.panelValue}>
                {props.value}
            </Typography>
        </div>
    )
}

const PoemOfTheDay = () => {

    const classes = useStyles()
    return (
        <Box className={classes.root}
             alignItems="center"
             justifyContent="center">
            <div className={classes.poemTitleContainer}>
                <Typography variant="h2" className={classes.poemTitleHeader}>
                    BÁSEŇ DNE
                </Typography>
                <div className={classes.poemTitleTextContainer}>
                    <Typography variant="h2" className={classes.poemTitleTextPoem}>
                        „Vodník“
                    </Typography>
                    <Typography variant="h5" className={classes.poemTitleTextAuthor}>
                        Karel Jaromír Erben
                    </Typography>
                </div>
            </div>


            <div className={classes.poemTextContainer}>
                <Typography variant="h4" className={classes.poemText}>
                    „Za těla i duše trápení jsem skládal tato slova to slova nevolníkova ze zajetí jsou, z vězení, kdy
                    všechno blahé, drahé, se jeví ztraceným,
                    kdy i tvůj život vlastní se nezdá být už tvým, kdy temno nade vším se svírá a prokleto se zdá být
                    všechno bytí Ne, nikdy ne! i tehdy na dně
                    srdce svítí nám stále ještě nepohaslá víra!“

                </Typography>
            </div>
        </Box>
    )
}

export default PoemOfTheDay