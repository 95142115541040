import React, {useContext, useEffect, useState} from "react"
import {
    Box, Button, Checkbox, createMuiTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControl, FormControlLabel, FormLabel,
    IconButton,
    ListItemIcon, Paper, Radio, RadioGroup
} from "@material-ui/core";
import MaterialTable from "material-table";
import {
    ArrowBack,
    CheckBox,
    Close,
    Code,
    ImportContacts,
    LibraryBooks,
    ListAlt,
    Note,
    Visibility
} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {userContext} from '../UserContext';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from "@material-ui/core/styles";
import Aos from "aos";
import ReactHtmlParser from 'react-html-parser';
import {useHistory} from "react-router-dom";
import InfoPanel from "../Homepage/InfoPanel"
import BackButton from "../BackButton";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import {SmartToy} from "@mui/icons-material";
import './sbirka.css';

const useStyles = makeStyles((theme) => ({

    header: {
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        marginTop: "0.5em",

    },

    headerText: {
        textAlign: "center",
        color: "grey",
        fontSize: "4em",
        textTransform: "capitalize",
        //marginLeft: "29vw",

        [theme.breakpoints.up(1930)]: {
            fontSize: "5em",
            //marginLeft: "32vw",
        },
    },


    container: {
        marginTop: "2em"
    },

    listPaper: {
        display: "flex",
        flexDirection: "column",
        minHeight: "70vh",
        maxHeight: "70vh",

        [theme.breakpoints.up(1930)]: {
            minHeight: "72vh",
            maxHeight: "72vh",
        },
    },

    InfoPanelStyles: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        paddingTop: "5em",
        fontSize: "80%",
        gap: "5em"
    },

    content: {
        overflow: "auto",
        marginLeft: "25%",
    },

    buttons: {
        display: "flex",
        flexDirection: "column",
    },
    button: {
        height: "8em",
        display: "flex",
        justifyContent: "flex-start",
    },

    buttonIcon: {
        marginLeft: "2em",
        color: "grey",
    },


    buttonText: {
        color: "grey",
        fontSize: "3em",
        textTransform: "capitalize",
        marginLeft: "0.8em",

        [theme.breakpoints.up(1930)]: {
            fontSize: "4em",
        },
    },


}));

function PoemDetail(props) {

    const classes = useStyles()
    const history = useHistory();
    const context = useContext(userContext);

    const [data, setData] = useState([])

    const [htmlText, setHtmlText] = useState(<p>some text</p>);


    const [header, setHeader] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [releasePlace, setReleasePlace] = useState("");
    const [releaseYear, setReleaseYear] = useState("");

    const [content, setContent] = useState(<p>some text</p>);


    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        fetch('https://ucl-poezie.fit.cvut.cz/api/books?menu', requestOptions)
            .then(response => response.json())
            .then(data => {
                setData(data)
            });

        Aos.init({
            duration: 2000
        });

        if (props && props.location && props.location.state && props.location.state.author)
            setAuthorName(props.location.state.author)
        if (props && props.location && props.location.state && props.location.state.title)
            setHeader(props.location.state.title)
        if (props && props.location && props.location.state && props.location.state.releasePlace)
            setReleasePlace(props.location.state.releasePlace)
        if (props && props.location && props.location.state && props.location.state.releaseYear)
            setReleaseYear(props.location.state.releaseYear)

        if (props && props.location && props.location.state && props.location.state.id) {
            const requestOptions2 = {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                id: props.location.state.id
            };


            const bookFinalText = "https://ucl-poezie.fit.cvut.cz/api/books/" + props.location.state.id + "/text"
            fetch(bookFinalText, requestOptions2)
                .then(response => response.text())
                .then(data => {
                    setHtmlText(data)
                    // console.log(data)
                })
        }

    }, [props.location.state]);


    useEffect(() => {
        const timer = setTimeout(() => {

            // const headerText = document.getElementsByClassName("nadpis")[0];
            const tableOfContent = document.getElementsByClassName("obsah_odkazy")[0];

            /* if (headerText) {
                 setHeader(headerText.textContent)
             }*/
            if (tableOfContent) {
                setContent(tableOfContent.innerHTML)
            }
        }, 1000);
        return () => clearTimeout(timer);

    }, [],);

    return (
        <>


            <div className={classes.container}>
                <div style={{display: "flex", flexDirection: "row", marginLeft: "0.5em"}}>
                    <BackButton button style={{marginTop: "1em", borderRadius: "5000"}}>
                        <ArrowBack style={{color: "black"}}/>
                    </BackButton>
                    <Typography variant="h6" style={{marginLeft: "0.5em", marginTop: "1.6em"}}>
                        Zpět na sbírku
                    </Typography>
                </div>
            </div>

            <Paper elevation={1}>
                <Grid container className={classes.container}>
                    <Grid item lg={4} md={12} xs={12}>
                        <Paper className={classes.listPaper}>
                            <Box className={classes.buttons}>

                                <Button size="large" className={classes.button} variant="default">
                                    <StickyNote2Icon className={classes.buttonIcon} fontSize="large"/>
                                    <Typography className={classes.buttonText}>
                                        Text
                                    </Typography>
                                </Button>

                                <Button size="large" className={classes.button} variant="default">
                                    <ImportContacts className={classes.buttonIcon} fontSize="large"/>
                                    <Typography className={classes.buttonText}>
                                        Sbírka
                                    </Typography>
                                </Button>

                                <Button size="large" className={classes.button} variant="default">
                                    <LibraryBooks className={classes.buttonIcon} fontSize="large"/>
                                    <Typography className={classes.buttonText}>
                                        Versologie
                                    </Typography>
                                </Button>


                                <Button size="large" className={classes.button} variant="default">
                                    <SmartToy className={classes.buttonIcon} fontSize="large"/>
                                    <Typography className={classes.buttonText}>
                                        AI výstupy
                                    </Typography>
                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item lg={4} md={12} xs={12}>
                        <Paper className={classes.listPaper}>

                            <div className="basen" id="1"><a name="basen_1"></a>
                                <div className="nadpis" style={{textAlign: "center"}}>
                                    <div className="b">V
                                        <div className="nbsp"></div>
                                        gymnasiu
                                    </div>
                                </div>
                                <div className="br"></div>
                                <div className="strofa" id="1">
                                    <l id="1">Překrásnou máme vyhlídku</l>
                                    <l id="2">na střechy pražských domů.</l>
                                    <l id="3">Při ní svou předu povídku</l>
                                    <l id="4">a sám se směji tomu.</l>
                                </div>
                                <div className="strofa" id="2">
                                    <l id="1">Melancholicky leží tu</l>
                                    <l id="2">komíny, střechy, věže.</l>
                                    <l id="3">Roj rýmů zvoní z úkrytu,</l>
                                    <l id="4">sen letí bez otěže.</l>
                                </div>
                                <div className="strofa" id="3">
                                    <l id="1">Melancholicky leží tu</l>
                                    <l id="2">obloha kouřem šedá</l>
                                    <l id="3">a v dálky tmavém úkrytu</l>
                                    <l id="4">tvář něčí srdce hledá.</l>
                                </div>
                                <div className="strana" id="2">5</div>
                            </div>

                        </Paper>
                    </Grid>

                    <Grid item lg={4} md={12} xs={12}>
                        <Paper className={classes.listPaper}>
                            <Box className={classes.InfoPanelStyles}>
                                <InfoPanel name="Autor" value="Karel Jaromír Erben"/>
                                <InfoPanel name="Sbírka" value="Kytice"/>
                                <InfoPanel name="Místo vydání" value="Plzeň"/>
                                <InfoPanel name="Rok vydání" value="1853"/>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>

        </>
    )
}

//#e6f4f3

export default PoemDetail