import React from "react"
import {
    Typography,
    makeStyles
} from "@material-ui/core";
import "aos/dist/aos.css"

const useStyles = makeStyles(theme => ({
    panelContainer: {
        display:"flex",
        flexDirection: "column",
    },
    panelName: {
        color: "grey",
        fontSize: "1.5em",

        [theme.breakpoints.up(1930)]: {
            fontSize: "2em",
        },
    },
    panelValue: {
        color: "grey",
        fontSize: "3em",

        [theme.breakpoints.up(1930)]: {
            fontSize: "4em",
        },
    },
}))

const InfoPanel = (props) => {

    const classes = useStyles()

return (
    <div className={classes.panelContainer}>
        <Typography variant="h4" className={classes.panelName}>
            {props.name}
        </Typography>
        <Typography variant="h4" className={classes.panelValue}>
            {props.value}
        </Typography>
    </div>
)}

export default InfoPanel

/*const InfoPanel = (props) => {

    const classes = useStyles()

    const [valueData, setValueData] = useState([]);


    useEffect(() => {


        setValueData(props.value)
        console.log("VALUE DATZA")

        console.log(valueData)
    }, []);

    return (
        <div className={classes.panelContainer}>
            <Typography variant="h4" className={classes.panelName}>
            </Typography>
            {valueData.map((item, key) =>
                <Typography variant="h4" className={classes.panelValue}>
                    {console.log(item)}
                </Typography>
            )}

        </div>
    )}

export default InfoPanel*/