import React, {useEffect, useState} from "react"
import {
    Typography,
    makeStyles, Paper, Divider, TextField, IconButton
} from "@material-ui/core";
import Aos from "aos"
import "aos/dist/aos.css"
import {Clear, EmojiEmotions, Search} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import {createTheme} from "@mui/material";

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: "5em",
        marginRight: "5em",
        padding: "3em",
        textAlign: "center",
        backgroundColor: "#ffffff",

    },
    heading: {
        color: "grey",
        fontSize: "4em",

        [theme.breakpoints.up(1930)]: {
            fontSize: "5em",
        },
    },
    subheading: {
        color: "grey",
        marginTop: "0.25em",
        fontSize: "2.1em",

        [theme.breakpoints.up(1930)]: {
            marginTop: "0.5em",
            fontSize: "2.4em",
        },
    },
    searchbar: {
        width: "60%",
        marginTop: "2em",

        [theme.breakpoints.up(1930)]: {
            width: "60%",
            marginTop: "3em"
        },
    }
}))


const Header = () => {

    /*useEffect(() => {
        Aos.init({duration: 2000});
    }, []);*/

    const [title, setTitle] = useState("");

    function fullTextSearching() {
        console.log("fullTextSearching")
        /* const requestOptions = {
             method: 'GET',
             headers: {'Content-Type': 'application/json'},
             id: props.location.state._id
         };
         const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + props.location.state._id + "?fulltext=" + encodeURIComponent(fullSearchValue)
         fetch(url, requestOptions)
             .then(response => response.json())
             .then(data => {
                 setData(data)
                 setShowSearchChanger(true)
                 setText("smthing")
             })*/
    }

    const classes = useStyles()
    return (
        <Paper elevation={0} className={classes.root}>

            <Typography className={classes.heading}>
                Česká elektronická knihovna
            </Typography>
            <Typography variant="h4" className={classes.subheading}>
                Poezie 19. a počátku 20. století
            </Typography>

            <TextField
                variant="outlined"
                id="searchbarhomepage"
                label="Vyhledávání"
                className={classes.searchbar}
                value={title}
                onChange={(event) => setTitle(event.target.value)}

                InputProps={{

                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton onClick={() => setTitle("")}>
                                <Clear/>
                            </IconButton>
                        </InputAdornment>
                }}
            />
        </Paper>
    )
}

export default Header