import React, {useContext, useEffect, useState} from "react"
import {
    Box, Button, Checkbox, createMuiTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, FormControl, FormControlLabel, FormLabel,
    IconButton,
    ListItemIcon, Paper, Radio, RadioGroup
} from "@material-ui/core";
import MaterialTable from "material-table";
import {ArrowBack, CheckBox, Close, Code, LibraryBooks, ListAlt, Note, Visibility} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {userContext} from '../UserContext';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from "@material-ui/core/styles";
import Aos from "aos";
import ReactHtmlParser from 'react-html-parser';
import {useHistory} from "react-router-dom";
import InfoPanel from "../Homepage/InfoPanel"
import BackButton from "../BackButton";


const useStyles = makeStyles((theme) => ({

    header: {
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        marginTop: "0.5em",

    },

    headerText: {
        textAlign: "center",
        color: "grey",
        fontSize: "4em",
        textTransform: "capitalize",
        //marginLeft: "29vw",

        [theme.breakpoints.up(1930)]: {
            fontSize: "5em",
            //marginLeft: "32vw",
        },
    },

    container: {
        marginTop: "2em"
    },

    listPaper: {
        display: "flex",
        flexDirection: "column",
        minHeight: "70vh",
        maxHeight: "70vh",

        [theme.breakpoints.up(1930)]: {
            minHeight: "72vh",
            maxHeight: "72vh",
        },
    },

    InfoPanelStyles: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        paddingTop: "5em",
        fontSize: "80%",
        gap: "5em"
    },

    content: {
        overflow: "auto",
        marginLeft: "25%",
    },


}));

function BookDetail(props) {

    const classes = useStyles()
    const history = useHistory();
    const context = useContext(userContext);

    const [data, setData] = useState([])

    const [htmlText, setHtmlText] = useState(<p>some text</p>);

    const [routeFromLibrary, setRouteFromLibrary] = useState(true);
    const [directLink, setDirectLink] = useState(true);

    const [header, setHeader] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [releasePlace, setReleasePlace] = useState("");
    const [releaseYear, setReleaseYear] = useState("");

    const [content, setContent] = useState(<p>some text</p>);


    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        fetch('https://ucl-poezie.fit.cvut.cz/api/books?menu', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(props.match.params.id)
                data.forEach((objekt, index) => {
                    if (objekt && objekt.id === props.match.params.id) {

                        if (objekt.author)
                            setAuthorName(objekt.author)
                        if (objekt.title)
                            setHeader(objekt.title)
                        if (objekt.releasePlace)
                            setReleasePlace(objekt.releasePlace)
                        if (objekt.releaseYear)
                            setReleaseYear(objekt.releaseYear)
                    }
                });

                setData(data)
            });

        Aos.init({
            duration: 2000
        });

        if (props && props.match && props.match.params && props.match.params.id) {
            const requestOptions2 = {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                id: props.match.params.id
            };


            const bookFinalText = "https://ucl-poezie.fit.cvut.cz/api/books/" + props.match.params.id + "/text"
            fetch(bookFinalText, requestOptions2)
                .then(response => response.text())
                .then(data => {
                    //console.log(data)
                    setHtmlText(data)
                })
        }
       /* if(props && props.location && props.location.state && !props.location.state.directLink) {
            setDirectLink(false)
            if (props.location.state.isRouteFromLibrary) setRouteFromLibrary(true)
            else setRouteFromLibrary(false)
        }
        else {
            // Handle the case where the property or object is undefined
            console.error('Cannot read directLink property. It is undefined.');
        }*/

    }, []);


    useEffect(() => {
        const timer = setTimeout(() => {

            // const headerText = document.getElementsByClassName("nadpis")[0];
            const tableOfContent = document.getElementsByClassName("obsah_odkazy")[0];

            /* if (headerText) {
                 setHeader(headerText.textContent)
             }*/
            if (tableOfContent) {
                setContent(tableOfContent.innerHTML)
            }
        }, 1000);
        return () => clearTimeout(timer);

    }, [],);

    return (
        <>


            <Grid container className={classes.container}>
                <Grid item lg={2} md={12} xs={12} direction="column">
                    {directLink ?
                        ""
                        :
                        <div style={{display: "flex", flexDirection: "row", marginLeft: "0.5em"}}>
                            <BackButton button style={{marginTop: "1em", borderRadius: "5000"}}>
                                <ArrowBack style={{color: "black"}}/>
                            </BackButton>


                            {routeFromLibrary ?
                                <Typography variant="h6" style={{marginLeft: "0.5em", marginTop: "1.6em"}}>
                                    Zpět na seznam knih

                                </Typography>

                                :

                                <Typography variant="h6" style={{marginLeft: "0.5em", marginTop: "1.6em"}}>
                                    Zpět na detail autora

                                </Typography>
                            }
                        </div>
                    }
                </Grid>

                <Grid item lg={8} md={12} xs={12}>
                    <Typography inputProps={{style: {textTransform: 'capitalize'}}} className={classes.headerText}>
                        {header}
                    </Typography>

                </Grid>

                <Grid item lg={2} md={12} xs={12}>

                </Grid>
            </Grid>

            <Paper elevation={1}>
                <Grid container className={classes.container}>
                    <Grid item lg={4} md={12} xs={12}>
                        <Paper className={classes.listPaper}>
                            <Typography variant="h6" className={classes.content}>
                                {ReactHtmlParser(content)}
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item lg={4} md={12} xs={12}>
                        <Paper className={classes.listPaper}>
                            {ReactHtmlParser(htmlText)}
                        </Paper>
                    </Grid>

                    <Grid item lg={4} md={12} xs={12}>
                        <Paper className={classes.listPaper}>
                            <Box className={classes.InfoPanelStyles}>
                                <InfoPanel name="Autor" value={authorName}/>
                                <InfoPanel name="Místo vydání" value={releasePlace}/>
                                <InfoPanel name="Rok vydání" value={releaseYear}/>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>

        </>
    )
}

//#e6f4f3

export default BookDetail