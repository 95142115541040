import React from "react"
import {
    Typography,
    makeStyles, Paper
} from "@material-ui/core";
import "aos/dist/aos.css"
import InfoPanel from "./InfoPanel";


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "0.5em",
        padding: "3em",
        textAlign: "center",
        backgroundColor: "#dddddd",
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",

        [theme.breakpoints.up(1930)]: {
            marginTop: "2em",
        },
    },
    panelContainer: {
        display:"flex",
        flexDirection: "column",
    },
    panelName: {
        color: "grey",
        fontSize: "1.5em",

        [theme.breakpoints.up(1930)]: {
            fontSize: "2em",
        },
    },
    panelValue: {
        color: "grey",
        fontSize: "3em",

        [theme.breakpoints.up(1930)]: {
            fontSize: "4em",
        },
    },
}))

const InfoPanels = () => {

    const classes = useStyles()
    return (
        <Paper elevation={0} className={classes.root}>

            <InfoPanel name = "Autorů" value = "256"/>
            <InfoPanel name = "Sbírek" value = "1253"/>
            <InfoPanel name = "Básní" value = "64000+"/>
            <InfoPanel name = "Veršů" value = "500000+"/>

        </Paper>
    )
}

export default InfoPanels