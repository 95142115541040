import React, {useContext, useEffect, useState} from "react"
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItemIcon,
    makeStyles
} from "@material-ui/core";
import MaterialTable from "material-table";
import {Close, LibraryBooks, Note} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";

import {useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {userContext} from "./UserContext";


const useStyles = makeStyles(theme => ({
    container: {
        background: "#a2a0a0"
    },
}))

function BookManagement() {
    const classes = useStyles;

    const [openDialog, setOpenDialog] = useState(false);
    const [data, setData] = useState([])

    const [dialogName, setDialogName] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [dialogText, setDialogText] = useState("");

    const context = useContext(userContext);
    const [saveDialog, setSaveDialog] = useState(false);
    const [lists, setLists] = useState([]);
    const [books, setBooks] = useState([]);
    const [bookID, setBookID] = useState("");
    const [bookTitle, setBookTitle] = useState("");

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        fetch('https://ucl-poezie.fit.cvut.cz/api/books?menu', requestOptions)
            .then(response => response.json())
            .then(data => {
                setData(data)

            });
    }, []);


    function handleReadAction(e, rowData) {
        setOpenDialog(true);
        setDialogName(rowData.title)
        setAuthorName(rowData.author)
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            id: rowData.id

        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/books/" + rowData.id + "?content"
        fetch(url, requestOptions)
            .then(response => response.text())
            .then(data => {
                setDialogText(data)
            })
    }

    function handleDeleteAction(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/books/" + encodeURIComponent(id.id)
        fetch(url, requestOptions)
            .then(response => console.log(response.status))
    }


    const handleClose = () => {
        setOpenDialog(false);
    };

    const history = useHistory();

    const navigateToEdit = (rowData) => {

     /*   history.push({
            pathname: '/bookedit',
            state: rowData.id
        });*/
    }



    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    function OnChangeHandler(files) {
        setSelectedFile(files)
    }

    const handleAddDialogClose = () => {
        setOpenAddDialog(false);
    };

    const handleAddDialogOpen = () => {
        setOpenAddDialog(true);
    };


    function handleSaveAction(e, rowData) {
        setBookTitle(rowData.title)
        setBookID(rowData.id)
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists"
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLists(data)
                setSaveDialog(true)
            });
    }

    const makeAction = (id) => {

        const options = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        const URL = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + encodeURIComponent(id)
        fetch(URL, options)
            .then(response => response.json())
            .then(data => {
                const newBook = {
                    bookId: bookID,
                    title: bookTitle,
                }
                const newBooks = data[0].books
                newBooks.push(newBook)
                data[0].books = newBooks
                const toSend = {
                    books: newBooks
                }
                const requestOptions = {
                    method: 'PUT',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(toSend)
                };
                const url = "https://ucl-poezie.fit.cvut.cz/api/users/" + encodeURIComponent(context.getEmail()) + "/bookLists/" + encodeURIComponent(id)
                fetch(url, requestOptions)
                    .then(response => {
                        if (response.status === 200)
                            closeSaveDialog()
                    })
            })
    };

    const closeSaveDialog = () => {
        setSaveDialog(false);
    };

    return (
        <>
            <MaterialTable
                title="Seznam básní"
                columns={[
                    {
                        title: 'Id', field: 'id', type: 'numeric', hidden: "true"
                    },
                    {
                        title: 'Název knihy', field: 'title', type: 'string', cellStyle: {
                            backgroundColor: 'white',
                            position: 'sticky',
                            left: 0
                        },
                        headerStyle: {
                            position: 'sticky',
                            left: 0,
                            whiteSpace: 'nowrap',
                            zIndex: 100
                        }
                    },
                    {title: 'Autor', field: 'author', type: 'string', headerStyle: {whiteSpace: 'nowrap',}},
                    {title: 'Rok vydání', field: 'releaseYear', type: 'numeric', headerStyle: {whiteSpace: 'nowrap',}},
                    {
                        title: 'Místo vydání',
                        field: 'releasePlace',
                        type: 'string',
                        headerStyle: {whiteSpace: 'nowrap',}
                    },
                ]}
                data={data}
                actions={[
                    {
                        icon: "visibility",
                        tooltip: 'Číst knihu',
                        onClick: (event, rowData) => handleReadAction(event, rowData)
                    },
                    {
                        icon: 'save',
                        tooltip: 'Uložit knihu',
                        onClick: (event, rowData) => handleSaveAction(event, rowData),
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Upravit knihu',
                        onClick: (event, rowData) => navigateToEdit(rowData),
                    },
                    {
                        icon: "add_box",
                        iconProps: {style: {color: "black"}},
                        tooltip: "Přidat knihu",
                        position: "toolbar",
                        onClick: handleAddDialogOpen
                    },


                ]}
                editable={{
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setData([...dataDelete]);
                                handleDeleteAction(oldData)
                                resolve();
                            }, 1000);
                        }),
                }}
                icons={{
                    Delete: "delete",
                }}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        overflow: 'hidden',
                        backgroundColor: "#009688",
                        fontWeight: 'bold',
                        color: "white",
                    },
                    paging: true,
                    pageSize: 20,       // make initial page size
                    emptyRowsWhenPaging: true,   //to make page size fix in case of less data rows
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                localization={{
                    header: {
                        actions: 'Akce'
                    },
                    toolbar: {
                        searchPlaceholder: "Vyhledávání"
                    },
                    body: {
                        emptyDataSourceMessage: "Žádné záznamy k zobrazení",
                        editRow: {
                            deleteText: "Opravdu chcete tento záznam smazat ?",
                            cancelTooltip: "Zrušit akci",
                            saveTooltip: "Provést akci",
                            deleteTooltip: "Smazat",
                            editTooltip: "Upravit",
                        }
                    },
                    pagination: {
                        labelRowsSelect: "záznamů",
                        labelRowsPerPage: "záznamů na stránku",
                        firstAriaLabel: "První stránka",
                        firstTooltip: "První stránka",
                        previousAriaLabel: "Předchozí stránka",
                        previousTooltip: "Předchozí stránka",
                        nextAriaLabel: "Další stránka",
                        nextTooltip: "Další stránka",
                        lastAriaLabel: "Poslední stránka",
                        lastTooltip: "Poslední stránka"
                    }
                }}
            />

            <Dialog onClose={handleClose} open={openDialog} fullWidth style={{textAlign: "center"}}>
                <DialogTitle>
                    <Box display="flex" alignItems="start" fontWeight="fontWeightMedium">
                        <Box>
                            <LibraryBooks/>
                        </Box>
                        <Box flexGrow={1}>{dialogName}</Box>
                        <Box>
                            <IconButton onClick={handleClose}>
                                <Close/>
                            </IconButton>
                        </Box>
                    </Box>
                    <Typography fontWeight="fontWeightLight">{authorName}</Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{whiteSpace: "pre-wrap"}}>
                        {dialogText}
                    </Typography>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>

            <Dialog onClose={handleAddDialogClose} open={openAddDialog}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" fontWeight="fontWeightMedium">
                        <Box flexGrow={1}>Nahrát nové knihy</Box>
                        <Box>
                            <IconButton onClick={handleAddDialogClose}>
                                <Close/>
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <input type="file" name="file" onChange={event => OnChangeHandler(event.target.files)}/>
                </DialogContent>

            </Dialog>

            <Dialog onClose={closeSaveDialog} open={saveDialog} fullWidth style={{textAlign: "center"}}>
                <DialogTitle>
                    <Box display="flex" alignItems="center" fontWeight="fontWeightMedium">
                        <Box flexGrow={1}>Vyberte seznam pro uložení knihy</Box>
                        <Box>
                            <IconButton onClick={closeSaveDialog}>
                                <Close/>
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {lists.map((item, key) =>
                            <Grid item xs={12}>
                                <List style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    <ListItemIcon style={{alignItems: "center"}}>
                                        <Note color="primary"/>
                                    </ListItemIcon>
                                    <ListItem button key={key} onClick={() => makeAction(item._id)}>
                                        <ListItemText inset primary={item.name}/>
                                    </ListItem>
                                </List>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>

        </>
    )
}

export default BookManagement



