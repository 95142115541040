import React, {useContext, useEffect} from "react"
import {userContext} from '../UserContext';
import {useHistory} from "react-router-dom";

const Logout = () => {

    const context = useContext(userContext);


    const history = useHistory();

    useEffect(() => {
        context.email("")
        context.login(false)
        context.role("all")

        history.push({
            pathname: '/login',
        });
    }, [context, history]);

    return (
        <h1>Jste odhlášeni</h1>
    )


}

export default Logout